import React from "react";
import { Link } from "react-router-dom";
import pic from "../uganda logo.jpeg";
import "./header.css";
import { useHistory, useLocation } from "react-router-dom";

const Header = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();

  const history = useHistory();
  const logout = () => {
    try {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      history.push("/");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white shadow-sm fixed-top">
      <div className="container">
        {/* Logo & Branding */}
        <Link className="navbar-brand d-flex align-items-center" to="/">
          <img src={pic} alt="MOH Logo" className="logo-img me-2" />
          <h5 className="mb-0 fw-bold">Ministry of Health</h5>
        </Link>

        {/* Navbar Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Navbar Items */}
        <div
          className="collapse navbar-collapse justify-content-center"
          id="navbarNav"
        >
          <ul className="navbar-nav">
            <li className="menuitem">
              <Link
                to="/"
                className={`menuitem d-flex align-items-center px-3 py-2 ${
                  location.pathname === "/p" ? "active" : "inactive"
                }`}
              >
                {/* <item.icon size={20} className="me-2" /> */}
                <span>Home</span>
              </Link>
            </li>

            <li className="nav-item dropdown">
              <Link
                className={`dropdown-toggle nav-lin menuitem d-flex align-items-center px-3 py-2 ${
                  location.pathname === "/p/facility/list"
                    ? "active"
                    : "inactive"
                }`}
                to="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Standard Downloads
              </Link>
              <ul className="dropdown-menu">
                <li>
                  <Link className="dropdown-item" to="/p/facility/list">
                    Full Master Facility List
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/p/facility/add">
                    Health Facilities By Region, Level and Ownership
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/p/facility/list">
                    Health Facilities By District, Level and Ownership
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/p/facility/add">
                    Health Facilities By Authority
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/p/facility/add">
                    Health Facilities By Ownership
                  </Link>
                </li>
              </ul>
            </li>
            <li className="menuitem">
              <Link
                to="/p/guidelines"
                className={`menuitem d-flex align-items-center px-3 py-2 ${
                  location.pathname === "/p/guidelines" ? "active" : "inactive"
                }`}
              >
                {/* <item.icon size={20} className="me-2" /> */}
                <span>SOPs & Guidelines</span>
              </Link>
            </li>

         

            {/* <li className="nav-item">
                            <Link className="nav-link" to="#">Contact Us</Link>
                        </li> */}
            <li className="nav-item">
              <a
                className={`menuitem d-flex align-items-center px-3 py-2 ${
                  location.pathname === "/api-docs/" ? "active" : "inactive"
                }`}
                href="http://localhost:9090/api-docs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                API Documentation
              </a>
            </li>
          </ul>
        </div>

        {/* Buttons */}
        <>
          {user ? (
            user.role === "admin" ? (
              <Link to="/d/admin/pending/approvals" className="btn btn-primary">
                My Account
              </Link>
            ) : user.role === "district" ? (
              <Link to="/d/district" className="btn btn-primary">
                My Account
              </Link>
            ) : user.role === "public" ? (
              <Link to="/d/requestor" className="btn btn-primary">
                My Account
              </Link>
            ) : null
          ) : (
            <div className="d-flex">
              <Link
                to="/p/registration"
                className="btn btn-outline-warning me-2"
              >
                Create Account
              </Link>
              <Link to="/login" className="btn btn-primary">
                Login
              </Link>
            </div>
          )}
        </>
      </div>
    </nav>
  );
};

export default Header;
