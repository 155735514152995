import {
  Home,
  File,
  CheckCircle,
  Clock,
  MessageSquare,
  List,
  Building2,
  Edit,
  XCircle,
  User,
  Hourglass,
  FileText,
  Users,
  Stethoscope,
} from "lucide-react";

export const requestorMenuItems = [
  { path: "/", label: "Home", icon: Home },
  {
    path: "/d/requestor",
    label: "My Facility Requests",
    icon: File,
    // badge: 5,
  },
  {
    path: "/d/requestor/approved",
    label: "Approved Requests",
    icon: CheckCircle,
  },
  {
    path: "/d/requestor/rejected",
    label: "Rejected Requests",
    icon: Clock,
  },
  { path: "/d/requestor/status", label: "Request Status Tracking", icon: MessageSquare },
];

export const DistrictmenuItems = [
  { path: "/", label: "Home", icon: Home },
  {
    path: "/d/district/myrequests",
    label: "My Facility Requests",
    icon: Hourglass,
  },
  {
    path: "/d/district/pending/approvals",
    label: "District Pending Approvals",
    icon: FileText,
    // badge: 3,
  },
  {
    path: "/d/district/facilities",
    label: "District Facilities",
    icon: CheckCircle,
  },
  {
    path: "/d/district/status",
    label: "Request Status Tracking",
    icon: MessageSquare,
  },
];

export const requestorTabs = [
  {
    path: "/d/requestor",
    key: "requests",
    icon: List,
    label: "My Requests",
  },
  {
    path: "/d/requestor/add",
    key: "addition",
    icon: Building2,
    label: "Facility Addition",
  },
  {
    path: "/d/requestor/update",
    key: "update",
    icon: Edit,
    label: "Facility Update",
  },
  {
    path: "/d/requestor/deactivate",
    key: "deactivate",
    icon: XCircle,
    label: "Facility Deactivation",
  },
  {
    path: "/d/requestor/status",
    key: "status",
    icon: User,
    label: "Request Tracking",
  },
];

export const districtTabs = [
  {
    path: "/d/district/myrequests",
    key: "requests",
    icon: List,
    label: "My Requests",
  },
  {
    path: "/d/district/add",
    key: "addition",
    icon: Building2,
    label: "Facility Addition",
  },
  {
    path: "/d/district/update",
    key: "update",
    icon: Edit,
    label: "Facility Update",
  },
  {
    path: "/d/district/deactivate",
    key: "deactivate",
    icon: XCircle,
    label: "Facility Deactivation",
  },
  {
    path: "/d/district/status",
    key: "status",
    icon: User,
    label: "Request Tracking",
  },
];

export const AdminMenuItems = [
  { path: "/", label: "Home", icon: Home },
  {
    path: "/d/admin/pending/approvals",
    label: "Pending Approvals",
    icon: MessageSquare,
    badge: 3,
  },
  {
    path: "/d/admin/add",
    label: "Facility Management",
    icon: Hourglass,
  },
  {
    path: "/d/admin/hierarchy",
    label: "Hierarchy Management",
    icon: FileText,
  },
  {
    path: "/d/admin/users",
    label: "User Management",
    icon: Users,
  },
  {
    path: "/d/admin/services",
    icon: Stethoscope,
    label: "Health Care Services",
  },
  // {
  //   path: "/d/admin/my/requests",
  //   icon: Stethoscope,
  //   label: "Direct Requests",
  // },
];



// export const requestorMenuItems = [
//   { path: "/", label: "Home", icon: "bi-house-door" },
//   {
//     path: "/d/requestor",
//     label: "My Facility Requests",
//     icon: "bi-file-earmark",
//     badge: 5,
//   },
//   {
//     path: "/d/requestor/approved",
//     label: "Approved Requests",
//     icon: "bi-check-circle",
//   },
//   {
//     path: "/d/requestor/rejected",
//     label: "Rejected Requests",
//     icon: "bi-clock",
//   },
 
//   { path: "/d/requestor/status", label: "Request Status Tracking", icon: "bi-chat" },
// ];

// export const DistrictmenuItems = [
//   { path: "/", label: "Home", icon: "bx bx-home-alt-2" },
//   {
//     path: "/d/district/myrequests",
//     label: "My Facility Requests",
//     icon: "bx bx-time",
//   },
//   {
//     path: "/d/district/pending/approvals",
//     label: "District Pending Approvals",
//     icon: "bx bx-file",
//     badge: 3,
//   },
//   // {
//   //   path: "/d/district/approved",
//   //   label: "Approved Requests",
//   //   icon: "bx bxs-check-circle",
//   // },
//   {
//     path: "/d/district/facilities",
//     label: "District Facilities",
//     icon: "bx bxs-check-circle",
//   },
//   {
//     path: "/d/district/status",
//     label: "Request Status Tracking",
//     icon: "bx bx-message",
//   },
// ];
// export const requestorTabs = [
//   {
//     path: "/d/requestor",
//     key: "requests",
//     icon: "bx-list-ul",
//     label: "My Requests",
//   },
//   {
//     path: "/d/requestor/add",
//     key: "addition",
//     icon: "bx-buildings",
//     label: "Facility Addition",
//   },
//   {
//     path: "/d/requestor/update",
//     key: "update",
//     icon: "bx-edit",
//     label: "Facility Update",
//   },

//   {
//     path: "/d/requestor/deactivate",
//     key: "deactivate",
//     icon: " bx-x-circle",
//     label: "Facility Deactivation",
//   },
//   {
//     path: "/d/requestor/status",
//     key: "status",
//     icon: "bx-user",
//     label: "Request Tracking",
//   },
// ];
// export const districtTabs = [
//   {
//     path: "/d/district/myrequests",
//     key: "requests",
//     icon: "bx-list-ul",
//     label: "My Requests",
//   },
//   {
//     path: "/d/district/add",
//     key: "addition",
//     icon: "bx-buildings",
//     label: "Facility Addition",
//   },
//   {
//     path: "/d/district/update",
//     key: "update",
//     icon: "bx-edit",
//     label: "Facility Update",
//   },

//   {
//     path: "/d/district/deactivate",
//     key: "deactivate",
//     icon: " bx-x-circle",
//     label: "Facility Deactivation",
//   },
//   {
//     path: "/d/district/status",
//     key: "status",
//     icon: "bx-user",
//     label: "Request Tracking",
//   },
// ];
// export const AdminMenuItems = [
//   { path: "/", label: "Home", icon: "bx bx-home-alt-2" },
//   {
//     path: "/d/admin/pending/approvals",
//     label: "Pending Approvals",
//     icon: "bx bx-message",
//     badge: 3,
//   },
//   {
//     path: "/d/admin/add",
//     label: "Facility Management",
//     icon: "bx bx-time",
//   },
//   {
//     path: "/d/admin/hierarchy",
//     label: "Hierarchy Management",
//     icon: "bx bx-file",
//   },
//   {
//     path: "/d/admin/users",
//     label: "User Management",
//     icon: "bx bxs-check-circle",
//   },

//   {
//     path: "/d/admin/services",
//     icon: "bx bx-band-aid",
//     label: "Health Care Services",
//   },
//   {
//     path: "/d/admin/my/requests",
//     icon: "bx bx-band-aid",
//     label: "Direct Requests",
//   },
  
// ];