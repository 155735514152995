import React from "react";
import { Link, useLocation } from "react-router-dom";

function CustomSideBar({ tabs, heading }) {
  const location = useLocation(); // Get current pathname

  return (
    <div className="sidebar border rounded p-3 shadow-sm">
      {heading? (
        <button className="btn btn-primary w-100 mb-3">
          <i className="bx bx-plus-circle"></i> {heading}
        </button>
      ):(  <button className="btn btn-primary w-100 mb-3">
        <i className="bx bx-plus-circle"></i> Facility Actions
      </button>)}
      <nav className="nav flex-column">
        {tabs.map((tab) => (
          <Link
            key={tab.key}
            className={`nav-link btn btn-light text-start w-100 p-2 rounded mb-2 ${
              location.pathname === tab.path ? "active" : ""
            }`}
            to={tab.path}
          >
                <tab.icon size={20} className="me-2" />
            {/* <i className={`bx ${tab.icon} me-2`}></i> */}
            {tab.label}
          </Link>
        ))}
      </nav>
    </div>
  );
}

export default CustomSideBar;

// import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

// function CustomSideBar({ tabs }) {

//   var currentPath = window.location.pathname;
//   useEffect(()=>{
//     currentPath =window.location.pathname;
//   },[])
//   return (
//     <div className="sidebar border rounded p-3 shadow-sm">
//       <nav className="nav flex-column">
//         {tabs.map((tab) => (
//           <Link
//             key={tab.key}
//             className={`nav-link btn btn-light text-start w-100 p-2 rounded mb-2 ${
//               currentPath === tab.path ? "active" : ""
//             }`}
//             to={tab.path}
//           >
//             <i className={`bx ${tab.icon} me-2`}></i>
//             {tab.label}
//           </Link>
//         ))}
//       </nav>
//     </div>
//   );
// }

// export default CustomSideBar;
