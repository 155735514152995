/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, useHistory } from "react-router-dom";
import pic from "../uganda logo.jpeg";
import "./header.css";

const Header = () => {
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user")) || {};

  const logout = () => {
    try {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      history.push("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="header-main navbar-expand-lg navbar-light ">
    <div className="container d-flex align-items-center">
      {/* Logo */}
      <Link className="navbar-brand fw-bold d-flex align-items-center" to="/">
        <img src={pic} alt="Logo" className="navbar-l me-2" style={{ width: "50px" }} /> National Health
        Facility Registry
      </Link>
  
      {/* Navbar Toggler */}
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
  
      {/* Navbar Items */}
      <div
        className="collapse navbar-collapse align-items-center justify-content-end z-3"
        id="navbarNav"
      >
        <ul className="d-flex align-items-center justify-centent-end flex-1">
          {/* Notifications Dropdown */}
          <li className="nav-item dropdown me-3">
            <a
              className="nav-lin position-relative"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
            >
              <i className="bx bx-bell fs-4"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-end">
              <li>
                <h6 className="dropdown-header">Notifications</h6>
              </li>
              <li>
                <a className="dropdown-item" href="#">🔔 New facility request added</a>
              </li>
              <li>
                <a className="dropdown-item" href="#">📩 You have new messages</a>
              </li>
              <li>
                <a className="dropdown-item text-center text-primary" href="#">View All</a>
              </li>
            </ul>
          </li>
  
          {/* User Dropdown */}
          <li className="nav-item dropdown">
            <a
              className="nav-lin dropdown-toggle d-flex align-items-center"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
            >
              <img
                src={pic}
                alt="User"
                className="rounded-circle me-2"
                width="40"
                height="40"
              />
              <span className="fw-bold">{user?.firstname || "Guest"}</span>
            </a>
            <ul className="dropdown-menu dropdown-menu-end">
              <li>
                <Link className="dropdown-item" to="/profile">
                  <i className="bx bx-user"></i> View Profile
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/settings">
                  <i className="bx bx-cog"></i> Settings
                </Link>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <button className="dropdown-item text-danger" onClick={logout}>
                  <i className="bx bx-log-out"></i> Logout
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
  
  );
};

export default Header;
