import React from "react";
import Header from "./Header";
import "./admin.css";
import SubHeader from "./SubHeaderRequestor";
import Footer from "./Footer";
import CustomSideBar from "../../reusable/CustomSideBar";
import { AdminMenuItems, DistrictmenuItems, districtTabs, requestorMenuItems, requestorTabs } from "./constants";

const Layout = ({ children }) => {
  const user = JSON.parse(localStorage.getItem("user"));



  return (
    <div className="wrapper">
        {/* <Header/> */}
      <div className="content">

      
        {/* <SubHeader menuItems={requestorMenuItems} /> */}
     <>
     {user && user.role === "public" && (
          <>
            {" "}
            <SubHeader menuItems={requestorMenuItems} />
            <div className="container py-5 ">
              <div className="row gap-5 py-5">
                <div className="col-2 py-5">
                  <CustomSideBar tabs={requestorTabs} />
                </div>

                <div className="col-9 py-5">{children}</div>
              </div>
            </div>
          </>
        )}
        {user && user.role === "admin" && (
          <>
            {" "}
            <SubHeader menuItems={AdminMenuItems} /> <div className="mt-5">
            <div className="container py-5">
              <div className="row gap-5 py-5">

                <div className="col-12 py-5">{children}</div>
              </div>
            </div>
            </div>
          </>
        )}
        {user && user.role === "district" && (
          <>
            {" "}
            <SubHeader menuItems={DistrictmenuItems} />
            <div className="container py-5">
              <div className="row gap-5 py-5">
                <div className="col-2 py-5">
                  <CustomSideBar tabs={districtTabs} />
                </div>

                <div className="col-9 py-5">{children}</div>
              </div>
            </div>
          </>
        )}
     </>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
