import React from "react";
import "./guidelines.css"; 
import { saveAs } from "file-saver";

const guidelines = [
    {
        title: "Facility Registry  Guidelines",
        description: "Comprehensive guide for managing health facility registry.",
        file: "Facility_Registry_Management_Guide.pdf",
    },
    {
        title: "Standard Operating Procedures (SOPs)",
        description: "Detailed SOPs for handling facility data and registry operations.",
        file: "Facility_Registry_SOPs.pdf",
    },
    {
        title: "Facility Data Collection Guide",
        description: "Guidance on collecting and updating facility data accurately.",
        file: "Facility_Data_Collection_Guide.pdf",
    },
];

const handleDownload = (fileName) => {
    // Simulate a file download (Replace with actual API if necessary)
    const fileUrl = `/downloads/${fileName}`; // Adjust path as needed
    saveAs(fileUrl, fileName);
};

const Guidelines = () => {
    return (
        <div className="g-container ">
            <h4 className="text-center mb-5">National Health Facility Registry Guidelines & SOPs</h4>
            <div className="row">
                {guidelines.map((guide, index) => (
                    <div key={index} className="col-md-4">
                        <div className="card guideline-card p-3 text-center">
                            <i className="bx bxs-file-pdf display-4 text-danger"></i>
                            <h5 className="mt-2">{guide.title}</h5>
                            <p className="small">{guide.description}</p>
                            <button className="btn btn-primary" onClick={() => handleDownload(guide.file)}>
                                <i className="bx bx-download"></i> Download
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Guidelines;