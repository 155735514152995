import React from "react";
import "./footer.css"; 

const Footer = () => {
    return (
        <footer className="footer bg-light py-3 mt-4">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6 text-center text-md-start">
                        <span>
                            &copy; 2025 <a href="#" className="footer-link">Ministry of Health</a>. All Rights Reserved.
                        </span>
                    </div>
                    <div className="col-md-6 text-center text-md-end">
                        <span>Version <strong>1.0</strong></span>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
