import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../../../helpers/api";
import TabbedLayout from "../../../components/reusable/TabbedLayout";

const DetailsTab = (facility) => {
  console.log("Facility Details ===>", facility)
  return (
    <div className="card shadow-sm border-0">
      <div className="card-header bg-light text-dark">
        <h4 className="mb-0 text-center">
          <i className="bx bx-sitemap"></i> {facility.facility.name} Facility
          Details
        </h4>
      </div>
      <div className="card-body">
        {/* Header with Approve and Reject Buttons */}
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h5>Health Facility Details</h5>
        </div>

        {/* Basic Information Card */}
        <div className="card mb-3">
          <div className="card-header bg-light fw-bold">Basic Information</div>
          <div className="card-body">
            <div className="row">
              {[
                ["Full Facility Identifier", facility.facility.unique_identifier],
                ["Facility Name", facility.facility.name],
                ["Level of Care", facility.facility.level],
                ["Ownership", facility.facility.ownership],
                ["Authority", facility.facility.authority],
                ["Operational Status", facility.facility.operational_status],
                ["License", facility.facility.license_status],
                ["Contact Person Name", facility.facility.contact_person_name],
                ["Contact Person Name", facility.facility.contact_person_title],
                ["Contact Person Email", facility.facility.contact_person_email],
                ["Contact Person Mobile", facility.facility.contact_person_mobile],
              ].map(([label, value], index) => (
                <div key={index} className="col-6 col-md-4">
                  <p>
                    <strong>{label}:</strong> {value}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Location Information Card */}
        <div className="card mb-3">
          <div className="card-header bg-light fw-bold">
            Location Information
          </div>
          <div className="card-body">
            <div className="row">
              {[
                ["Region", facility.facility.Region.name],
                ["District", facility.facility.District.name],
                ["Subcounty", facility.facility.SubCounty.name],
                ["Address", facility.facility.physical_location],
                ["Latitude", facility.facility.latitude],
                ["Longitude", facility.facility.longitude],
                ["Bed Capacity", facility.facility.beds],
              ].map(([label, value], index) => (
                <div key={index} className="col-6 col-md-4">
                  <p>
                    <strong>{label}:</strong> {value}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Identification = () => {
  return <>Identification</>;
};
const Location = () => {
  return <>location</>;
};
const Classification = () => {
  return <>classification</>;
};
const Service = () => {
  return <>service</>;
};

const FacilityDetails = () => {
  const { id } = useParams();
  const [facility, setFacility] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentTab, setCurrentTab] = useState("details");



  const tabsContent = [
    { key: "details", icon: "bx-list-ul", label: "Facility Details", component: <DetailsTab facility={facility} /> },
    { key: "identification", icon: "bx-user", label: "Identification", component: <p>identification</p> },
    { key: "location", icon: "bx-map", label: "Location", component: <p>location</p> },
    { key: "classification", icon: "bx-edit", label: "Classification", component: <p>classification</p> },
    { key: "service", icon: "bx-clinic", label: "Health Services", component: <p>services</p> },
  ];

  useEffect(() => {
    fetchFacilityDetails();
  }, []);

  const fetchFacilityDetails = async () => {
    try {
      const response = await API.get(`/facility/${id}`);
      console.log("XXXX===>", response);
      setFacility(response.data);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch facility details.");
      setLoading(false);
    }
  };

  if (loading) return <p>Loading facility details...</p>;
  if (error) return <p style={{ color: "red" }}>{error}</p>;

  const tabs = {
    details: <DetailsTab facility={facility} />,
    identification: <Identification />,
    location: <Location />,
    classification: <Classification />,
    service: <Service />,
  };

  return (
    <><TabbedLayout tabs={tabsContent} /></>
  );
};

export default FacilityDetails;
