import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Identification from "./Identification";
import Classification from "./Classification";
import Contacts from "./Contacts";
import Documents from "./Documents";
import Services from "./Services";
import API from "../../../helpers/api";
import './addition.css';

const FacilityAddition = () => {
    const [loading, setLoading] = useState(true);
    const [level, setLevel] = useState("General Hospital");
    const [services, setServices] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [step, setStep] = useState(1);
    const totalSteps = 5;
    const history = useHistory();

    const [formData, setFormData] = useState({
        name: "",
        level: "General Hospital",
        ownership: "",
        authority: "",
        operational_status: "",
        license_status: "",
        physical_location: "",
        latitude: "",
        longitude: "",
        contact_personemail: "",
        contact_personmobile: "",
        contact_personname: "",
        contact_persontitle: "",
        region_id: "",
        district_id: "",
        sub_county_id: "",
        bed_capacity: "",
        regulatory_body: "",
        license_number: "",
        date_registered: "",
        facility_inspection_status: "",
    });

    const [documents, setDocuments] = useState({
        operating_license: null,
        council_minutes: null,
        district_letter: null,
    });

    useEffect(() => {
        fetchServices(level);
    }, [level]);

    const fetchServices = async (selectedLevel) => {
        try {
            const response = await API.get(`/services/${selectedLevel}`);
            setServices(response.data);
            setSelectedServices(response.data.map(service => service.service_name));
        } catch (err) {
            console.error("Error fetching services:", err);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value.trim(), // Ensure no accidental spaces
        }));
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        if (files.length > 0) {
            setDocuments((prev) => ({
                ...prev,
                [name]: files[0],
            }));
        }
    };

    const handleCheckboxChange = (serviceName) => {
        setSelectedServices((prev) =>
            prev.includes(serviceName)
                ? prev.filter((item) => item !== serviceName)
                : [...prev, serviceName]
        );
    };

    const token = localStorage.getItem("token");

    const handleSubmit = async (e) => {
        e.preventDefault();

        const submissionData = new FormData();
        Object.entries(formData).forEach(([key, value]) => {
            if (value) submissionData.append(key, value);
        });

        submissionData.append("services", JSON.stringify(selectedServices));
        submissionData.append("request_type", "Addition_Request");
        submissionData.append("comments", "Addition Request Iniatied");
        submissionData.append("status", "Addition Request Iniatied");

        Object.entries(documents).forEach(([key, value]) => {
            if (value) submissionData.append(key, value);
        });

        console.log("Submitting Data:", Object.fromEntries(submissionData)); // Debugging output

        try {
            await API.post("/requests", submissionData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                },
            });
            history.push("/d/requestor");
            toast.success("Facility details saved successfully!", { position: "top-center" });
        } catch (error) {
            console.error("Error saving facility details:", error);
            toast.error("Failed to save facility details.");
        }
    };

    const steps = [
        { number: 1, label: "License" },
        { number: 2, label: "Classification" },
        { number: 3, label: "Contact Details" },
        { number: 4, label: "Health Services" },
        { number: 5, label: "Documents" },
    ];

    return (
        <div className="container">
            <div className="row ">
                <div className="col-12">
                    <div className="card shadow p-4">
                        {/* Stepper Header */}
                        <div className="d-flex justify-content-between align-items-center step-header">
                            {steps.map((item, index) => (
                                <div key={item.number} className="step-container">
                                    <div className={`circle ${step >= item.number ? "completed" : ""}`}>
                                        {item.number}
                                    </div>
                                    <div className="step-label">{item.label}</div>
                                    {index < steps.length - 1 && (
                                        <div className={`arrow ${step > item.number ? "completed" : ""}`}>
                                            ➜
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>

                        {/* Step Content */}
                        <div className="step-content mt-4">
                            {step === 1 && <Identification formData={formData} handleChange={handleChange} />}
                            {step === 2 && <Classification formData={formData} handleChange={handleChange} />}
                            {step === 3 && <Contacts formData={formData} handleChange={handleChange} />}
                            {step === 4 && (
                                <Services
                                    services={services}
                                    level={level}
                                    setLevel={setLevel}
                                    selectedServices={selectedServices}
                                    handleCheckboxChange={handleCheckboxChange}
                                />
                            )}
                            {step === 5 && <Documents handleFileChange={handleFileChange} />}
                        </div>

                        {/* Navigation Buttons */}
                        <div className="d-flex justify-content-between mt-4">
                            <button
                                className="btn btn-secondary"
                                onClick={() => setStep(step - 1)}
                                disabled={step === 1}
                            >
                                Previous
                            </button>
                            {step < totalSteps ? (
                                <button className="btn btn-primary" onClick={() => setStep(step + 1)}>
                                    Next
                                </button>
                            ) : (
                                <button className="btn btn-success" onClick={handleSubmit}>
                                    Submit
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FacilityAddition;