
import MyRequests from "../../components/FNTables/MyRequests";

function FacilityRequestsMain() {
  
  return (
  <>       <MyRequests /></>
  );
}

export default FacilityRequestsMain;
