/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import CustomTable from "../reusable/CustomTable";
import API from "../../helpers/api";

const PendingDistrictApprovals = () => {
  const [facilities, setFacilities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    fetchRequests();
  }, []);

  const fetchRequests = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await API.get("/requests/district", {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log("reqs===", response.data);

      setFacilities(response.data || []);
    } catch (err) {
      setError(err.message || "Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  const handleFacilityClick = (id, name) => {
    history.push({
      pathname: `/d/district/details/${id}`,
      state: { facilityName: name },
    });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="text-warning">No Requests</p>;

  const columns = [
    { header: "Facility Name", accessor: "name" },

    {
      header: "Requested By",
      accessor: "id",
      render: (facility) => (
        <div>
          <a href="#" className="text-decorations-none">
            <span className="me-1 text-muted text-sm">Name:</span>{" "}
            <strong>{facility.requested_by_name}</strong>
          </a><br/>
          <a href="#" className="text-decorations-none">
            <span className="me-1 text-muted text-sm">Email:</span>{" "}
            <strong>{facility.requested_by_email}</strong>
          </a><br/>
          <a href="#" className="text-decorations-none">
            <span className="me-1 text-muted text-sm">Mobile:</span>{" "}
            <strong>{facility.requested_by_mobile}</strong>
          </a>
        </div>
      ),
    },
    { header: "Level of Care", accessor: "level" },
    { header: "Ownership", accessor: "ownership" },
    // { header: "Authority", accessor: "authority" },
    { header: "Request Type", accessor: "request_type" },
    { header: "Status", accessor: "status" },
    {
      header: "Requested Date",
      accessor: "createdAt",
      render: (facility) =>
        facility.createdAt
          ? new Date(facility.createdAt).toLocaleDateString()
          : "N/A",
    },
    {
      header: "Details",
      accessor: "id",
      render: (facility) => (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleFacilityClick(facility.id, facility.name);
          }}
          className="btn btn-success bg-success border-0 rounded bg-opacity-10 text-success btn-sm"
        >
          Review
        </a>
      ),
    },
  ];

  return (
    <div className="container ">
      <div className="row ">
        <div className="col-12">
          <div class="card">
            <div class="card-header">
              <h6 class="slim-card-title">
                District Pending Facility Requests Approvals
              </h6>
            </div>
            <CustomTable
              data={facilities}
              columns={columns}
              itemsPerPage={30}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingDistrictApprovals;
