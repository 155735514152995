import { Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "./subheader.css";
import Header from "./Header";

function SubHeader({ menuItems }) {
  const location = useLocation();

  return (
    <div className="bg-light  border-bottom py-2 shadow-sm fixed-top z-3">
      <Header />
      <Container className="p-2">
        <div className="d-flex  justify-content-between w-100">
          {menuItems.map((item, index) => (
            <a href="#" className="menuitem" key={index}>
              <Link
                to={item.path}
                className={`menuitem d-flex align-items-center px-3 py-2 ${
                  location.pathname === item.path ? "active" : "inactive"
                }`}
              >
                <item.icon size={20} className="me-2" />
                <span>{item.label}</span>
                {item.badge && (
                  <span className="badge bg-danger ms-2">{item.badge}</span>
                )}
              </Link>
            </a>
          ))}
        </div>
      </Container>
    </div>
  );
}

export default SubHeader;
