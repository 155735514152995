import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import API from "../../../../helpers/api";
import { useHistory } from "react-router-dom";
import Layout from '../layout'

const FacilityUpdate = () => {
  const [loading, setLoading] = useState(false);
  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [subCounties, setSubCounties] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [facility_id, setFacilityId] = useState(null);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [supportDocument, setSupportDocument] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    level: "",
    ownership: "",
    authority: "",
    region_id: null,
    district_id: null,
    sub_county_id: null
  });

  console.log(facility_id)

  const history = useHistory();

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    try {
      const token = localStorage.getItem("token");
      const [facilitiesRes, regionsRes] = await Promise.all([
        API.get("/facility/my", { headers: { Authorization: `Bearer ${token}` } }),
        API.get("/adminareas/regions"),
      ]);

      setFacilities(facilitiesRes.data || []);
      setRegions(regionsRes.data.map(r => ({ value: r.id, label: r.name })));
    } catch (error) {
      toast.error("Failed to fetch initial data");
    }
  };

  const token = localStorage.getItem("token");

  const handleFacilityChange = async (selectedOption) => {
    setLoading(true);
    try {
      const response = await API.get(`/facility/${selectedOption.value}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const facilityData = response.data;

      setFacilityId(selectedOption.value)
      setSelectedFacility(selectedOption);

      setFormData({
        name: facilityData.name || "",
        level: facilityData.level || "",
        ownership: facilityData.ownership || "",
        authority: facilityData.authority || "",
        region_id: facilityData.Region?.id || null,
        district_id: facilityData.District?.id || null,
        sub_county_id: facilityData.SubCounty?.id || null,
      });

      // Preload dependent selections
      if (facilityData.Region?.id) await handleRegionChange({ value: facilityData.Region.id, label: facilityData.Region.name });
      if (facilityData.District?.id) await handleDistrictChange({ value: facilityData.District.id, label: facilityData.District.name });
      // Preselect Subcounty
      if (facilityData.SubCounty?.id) {
        const selectedSubCounty = { value: facilityData.SubCounty.id, label: facilityData.SubCounty.name };
        setFormData((prev) => ({
          ...prev,
          sub_county_id: selectedSubCounty.value,
        }));
      }

    } catch (error) {
      toast.error("Failed to fetch facility details");
    }
    setLoading(false);
  };

  const handleChange = (name, value) => {
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleRegionChange = async (selectedOption) => {
    setFormData(prev => ({
      ...prev,
      region_id: selectedOption.value,
      district_id: null,
      sub_county_id: null,
    }));

    if (!selectedOption.value) return;

    try {
      const response = await API.get(`/adminareas/district?region_id=${selectedOption.value}`);
      setDistricts(response.data.map(d => ({ value: d.id, label: d.name })));
      setSubCounties([]);
    } catch (error) {
      toast.error("Failed to fetch districts");
    }
  };

  const handleDistrictChange = async (selectedOption) => {
    setFormData(prev => ({
      ...prev,
      district_id: selectedOption.value,
      sub_county_id: null,
    }));

    if (!selectedOption.value) return;

    try {
      const response = await API.get(`/adminareas/subcounty?district_id=${selectedOption.value}`);
      setSubCounties(response.data.map(s => ({ value: s.id, label: s.name })));
    } catch (error) {
      toast.error("Failed to fetch sub-counties");
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setSupportDocument(file);

    setFormData(prev => ({
      ...prev,
      support_document: file,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const submissionData = new FormData();

    submissionData.append("name", formData.name || "");
    submissionData.append("level", formData.level || "");
    submissionData.append("facility_id", facility_id || "");
    submissionData.append("ownership", formData.ownership || "");
    submissionData.append("authority", formData.authority || "");
    submissionData.append("region_id", formData.region_id || "");
    submissionData.append("district_id", formData.district_id || "");
    submissionData.append("sub_county_id", formData.sub_county_id || "");

    submissionData.append("request_type", "Update_Request");
    submissionData.append("comments", "Update Request Iniatied");
    submissionData.append("status", "Update Request Iniatied");

    if (supportDocument) {
      submissionData.append("support_document", supportDocument);
    }

    try {
      const token = localStorage.getItem("token");
      await API.post("/requests", submissionData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      history.push('/d/requestor')

      toast.success("Facility updated successfully");
    } catch (error) {
      toast.error("Failed to update facility");
    }

    setLoading(false);
  };

  return (
    <Layout>
      <div className="container mt-4">
        <div className="card shadow-lg">
          <div className="card-body">
            <h2 className="card-title text-center mb-4">Update Facility Information</h2>
            <form onSubmit={handleSubmit}>
              {/* Facility Selection */}
              <div className="mb-4">
                <label className="form-label">Select Health Facility</label>
                <Select
                  placeholder="Select a facility to update"
                  options={facilities.map((f) => ({ value: f.id, label: f.name }))}
                  onChange={handleFacilityChange}
                  value={selectedFacility}
                  isDisabled={loading}
                />
              </div>

              {/* Facility Information */}
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label className="form-label">Facility Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={formData.name}
                    onChange={(e) => handleChange("name", e.target.value)}
                    required
                    disabled={!selectedFacility}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Level of Care</label>
                  <Select
                    placeholder="Select Level of Care"
                    options={[
                      { value: "General Hospital", label: "General Hospital" },
                      { value: "Clinic", label: "Clinic" },
                      { value: "Pharmacy", label: "Pharmacy" },
                      { value: "Laboratory/Diagnostic center", label: "Laboratory/Diagnostic center" },
                      { value: "Radiology (Imaging)", label: "Radiology (Imaging)" },
                    ]}
                    value={{ value: formData.level, label: formData.level }}
                    onChange={(selected) => handleChange("level", selected.value)}
                    isDisabled={!selectedFacility}
                  />
                </div>
              </div>

              {/* Region, District, Subcounty */}
              <div className="row">
                <div className="col-md-4 mb-3">
                  <label className="form-label">Region</label>
                  <Select
                    placeholder="Select Region"
                    options={regions}
                    value={regions.find(r => r.value === formData.region_id) || null}
                    onChange={handleRegionChange}
                    isDisabled={!selectedFacility}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">District</label>
                  <Select
                    placeholder="Select District"
                    options={districts}
                    value={districts.find(d => d.value === formData.district_id) || null}
                    onChange={handleDistrictChange}
                    isDisabled={!formData.region_id}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Subcounty</label>
                  <Select
                    placeholder="Select Subcounty"
                    options={subCounties}
                    value={subCounties.find(s => s.value === formData.sub_county_id) || null}
                    onChange={(selected) => handleChange("sub_county_id", selected.value)}
                    isDisabled={!formData.district_id}
                  />
                </div>
              </div>

              {/* Supporting Document Upload */}
              <div className="mb-3">
                <label className="form-label">Upload Update Supporting Document</label>
                <input
                  type="file"
                  className="form-control"
                  name="support_document"
                  onChange={handleFileChange}
                  disabled={!selectedFacility}
                />
              </div>

              {/* Submit Button */}
              <div className="text-center mt-4">
                <button type="submit" className="btn btn-primary" disabled={!selectedFacility || loading}>
                  {loading ? "Updating..." : "Update Facility"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>

  );
};

export default FacilityUpdate;