import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const Failed = () => {
    const history = useHistory();
    const location = useLocation();
    const errorMessage = location.state?.error || "Something went wrong! Please try again.";

    return (
        <section>
            <div className="d-flex justify-content-center">
                <div className="w-50 card text-center p-4 shadow-lg">
                    <div className="card-body">
                        <i className="bx bx-error-circle text-danger display-1"></i>
                        <h2 className="text-danger mt-3">User Account Registration Failed</h2>
                        <p className="text-muted">{errorMessage}</p>
                        <button className="btn btn-warning mt-3" onClick={() => history.push('/p/registration')}>
                            Try Again
                        </button>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default Failed;
