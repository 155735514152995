import React from 'react';

const Services = ({ services = [], level, setLevel, handleCheckboxChange, selectedServices = [] }) => {
    console.log(services)
    return (
        <div className="row">
            <div className="col-10 mx-auto">
                <h5 className="mb-4">Health Care Services</h5>

                {/* Facility Level Selection */}
                {/* <div className="mb-3">
                    <label className="form-label">Facility Level</label>
                    <select
                        className="form-select custom-select"
                        value={level}
                        onChange={(e) => setLevel(e.target.value)}
                    >
                        <option value="National Referral Hospital">National Referral Hospital</option>
                        <option value="Regional Referral Hospital">Regional Referral Hospital</option>
                        <option value="General Hospital">General Hospital</option>
                        <option value="HC IV">HC IV</option>
                        <option value="HC III">HC III</option>
                        <option value="HC II">HC II</option>
                        <option value="VHT">VHT</option>
                    </select>
                </div> */}

                {/* Services Checkbox Selection */}
                <div className="mb-3">
                    <label className="form-label">Select Services</label>
                    <div className="row">
                        {services.length > 0 ? (
                            services.map((service) => (
                                <div key={service.id} className="col-md-4 col-sm-6 col-12 mb-2">
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={`service-${service.id}`}
                                            checked={selectedServices.includes(service.service_name)}
                                            onChange={() => handleCheckboxChange(service.service_name)}
                                        />
                                        <label className="form-check-label" htmlFor={`service-${service.id}`}>
                                            {service.service_name}
                                        </label>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-muted">No services available.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;
