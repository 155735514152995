/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Select from "react-select";
import { saveAs } from "file-saver";
import { useHistory } from "react-router-dom";
import API from "../../../helpers/api";

import "./facilities.css";
import CustomTable from "../../../components/reusable/CustomTable";

const Facilities = () => {
  const [facilities, setFacilities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    level: "",
    ownership: "",
    authority: "",
    region_id: "",
    district_id: "",
    sub_county_id: "",
  });

  const [regions, setRegions] = useState([]);
  const [localGovts, setLocalGovts] = useState([]);
  const [subCounties, setSubCounties] = useState([]);

  const history = useHistory();

  useEffect(() => {
    fetchFacilities();
  }, [filters]);

  const fetchFacilities = async () => {
    try {
      const queryParams = new URLSearchParams(filters).toString();
      const response = await API.get(`/facility?${queryParams}`);
      console.log(response)
      setFacilities(response.data.facilities);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch data");
      setLoading(false);
    }
  };

  const downloadExcel = (facilities) => {
    if (!facilities || facilities.length === 0) {
      alert("No data available for download.");
      return;
    }

    const formattedData = facilities.map((facility) => ({
      "Unique Identifier": facility.unique_identifier,
      "Facility Name": facility.name,
      Level: facility.level,
      Ownership: facility.ownership,
      Authority: facility.authority,
      SubCounty: facility.SubCounty?.name || "N/A",
      "Local Govt": facility.District?.name || "N/A",
      Region: facility.Region?.name || "N/A",
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Facilities");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    saveAs(data, "Facilities_List.xlsx");
  };

  const printPDF = (facilities) => {
    if (!facilities || facilities.length === 0) {
      alert("No data available for download.");
      return;
    }

    const doc = new jsPDF();
    doc.text("Uganda Master Health Facility List", 14, 10);

    const tableColumn = [
      "Unique Identifier",
      "Facility Name",
      "Level",
      "Ownership",
      "Authority",
      "SubCounty",
      "District",
      "Region",
    ];

    const tableRows = facilities.map((facility) => [
      facility.unique_identifier,
      facility.name,
      facility.level,
      facility.ownership,
      facility.authority,
      facility.SubCounty?.name || "N/A",
      facility.District?.name || "N/A",
      facility.Region?.name || "N/A",
    ]);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
    });

    doc.save("Facilities_List.pdf");
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  const fetchRegions = async () => {
    try {
      const response = await API.get("/adminareas/regions");
      setRegions(
        response.data.map((region) => ({
          value: region.id,
          label: region.name,
        }))
      );
    } catch (error) {
      console.error("Error fetching regions:", error);
    }
  };

  const fetchDistricts = async (region_id) => {
    try {
      const response = await API.get(
        `/adminareas/district?region_id=${region_id}`
      );
      setLocalGovts(
        response.data.map((lg) => ({ value: lg.id, label: lg.name }))
      );
      setSubCounties([]);
    } catch (error) {
      console.error("Error fetching Districts:", error);
    }
  };

  const fetchSubCounties = async (district_id) => {
    try {
      const response = await API.get(
        `/adminareas/subcounty?district_id=${district_id}`
      );
      setSubCounties(
        response.data.map((sc) => ({ value: sc.id, label: sc.name }))
      );
    } catch (error) {
      console.error("Error fetching sub-counties:", error);
    }
  };

  const handleFilterChange = (selectedOption, field) => {
    const newValue = selectedOption ? selectedOption.value : "";

    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: newValue,
    }));

    if (field === "region_id") {
      fetchDistricts(newValue);
      setFilters((prev) => ({ ...prev, district_id: "", sub_county_id: "" }));
    } else if (field === "district_id") {
      fetchSubCounties(newValue);
      setFilters((prev) => ({ ...prev, sub_county_id: "" }));
    }
  };

  const clearFilters = () => {
    setFilters({
      level: "",
      ownership: "",
      authority: "",
      region_id: "",
      district_id: "",
      sub_county_id: "",
    });
    setLocalGovts([]);
    setSubCounties([]);
  };

  const handleFacilityClick = (facility) => {
    console.log("name and id ", facility);
    history.push({
      pathname: `/p/facility/${facility.id}`,
      state: facility,
    });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p style={{ color: "red" }}>{error}</p>;

  // const truncateString = (str, num) => {
  //   return str.length > num ? str.slice(0, num) + "..." : str;
  // };

  const truncateString = (str, num) => {
    return str.length > num ? "".repeat(num) + str.slice(num) : str;
  };
  const columns = [
    {
      header: "Facility Id",
      accessor: "unique_identifier",
      render: (facility) => <>{truncateString(facility.unique_identifier, 6)}</>,
    },
    { header: "Facility Name", accessor: "name" },
    { header: "Level", accessor: "level" },
    { header: "Ownership", accessor: "ownership" },
    { header: "Authority", accessor: "authority" },
    { header: "SubCounty", accessor: (facility) => facility.SubCounty?.name },
    { header: "District", accessor: (facility) => facility.District?.name },
    { header: "Region", accessor: (facility) => facility.Region?.name },

    {
      header: "Details",
      accessor: "unique_identifier",
      render: (facility) => (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleFacilityClick(facility);
          }}
          className="btn btn-primary btn-sm"
        >
          View
        </a>
      ),
    },
  ];

  return (
    <div className="container mb-3">
      <section>
        <div className="container">
          <div className="card shadow-sm">
            <div className="row align-items-center">
              <div className="col-6">
                <h4 className="fw-bold">Uganda Master Health Facility List</h4>
              </div>
              <div className="col-6 text-end">
                <div className="btn-group">
                  <button
                    className="btn btn-outline-primary"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                  >
                    <i className="bx bx-filter"></i> Apply Filters
                  </button>
                  <button
                    className="btn btn-outline-success"
                    onClick={() => downloadExcel(facilities)}
                  >
                    <i className="bx bx-download"></i> Download
                  </button>
                  <button
                    className="btn btn-outline-dark"
                    onClick={() => printPDF(facilities)}
                  >
                    <i className="bx bx-printer"></i> Print
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Filter Sidebar */}
          <div
            className="offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvasRight"
          >
            <div className="offcanvas-header">
              <h5>Filter Options</h5>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
              ></button>
            </div>
            <div className="offcanvas-body">
              <label className="form-label">Level</label>
              <Select
                options={[
                  { value: "NRH", label: "NRH" },
                  { value: "RRH", label: "RRH" },
                  { value: "RBB", label: "RBB" },
                  { value: "HC II", label: "HC II" },
                  { value: "HC III", label: "HC III" },
                  { value: "HC IV", label: "HC IV" },
                  { value: "Clinic", label: "Clinic" },
                  { value: "Drug Shop", label: "Drug Shop" },
                  { value: "BCDP", label: "BCDP" },
                  { value: "General Hospital", label: "General Hospital" },
                  {
                    value: "Specialized Hospital",
                    label: "Specialized Hospital",
                  },
                ]}
                onChange={(selected) => handleFilterChange(selected, "level")}
                isClearable
              />

              <label className="form-label mt-2">Ownership</label>
              <Select
                options={[
                  { value: "GOV", label: "GOV" },
                  { value: "PFP", label: "PFP" },
                  { value: "PNFP", label: "PNFP" },
                ]}
                onChange={(selected) =>
                  handleFilterChange(selected, "ownership")
                }
                isClearable
              />

              <label className="form-label mt-2">Authority</label>
              <Select
                options={[
                  { value: "GOV", label: "GOV" },
                  { value: "UPS", label: "UPS" },
                  { value: "LG", label: "LG" },
                  { value: "UPDF", label: "UPDF" },
                  { value: "UPMB", label: "UPMB" },
                  { value: "UCMB", label: "UCMB" },
                  { value: "Private", label: "Private" },
                  { value: "NGO", label: "NGO" },
                  { value: "MOH", label: "MOH" },
                  { value: "UCBHCA", label: "UCBHCA" },
                  { value: "MoES", label: "MoES" },
                  { value: "UMMB", label: "UMMB" },
                  { value: "SDA", label: "SDA" },
                  { value: "BOU", label: "BOU" },
                  { value: "UOMB", label: "UOMB" },
                  { value: "SOS", label: "SOS" },
                  { value: "UNHCR", label: "UNHCR" },
                ]}
                onChange={(selected) =>
                  handleFilterChange(selected, "authority")
                }
                isClearable
              />

              <label className="form-label mt-2">Region</label>
              <Select
                options={regions}
                onChange={(selected) =>
                  handleFilterChange(selected, "region_id")
                }
                isClearable
              />

              <label className="form-label mt-2">Local Govt</label>
              <Select
                options={localGovts}
                onChange={(selected) =>
                  handleFilterChange(selected, "district_id")
                }
                isClearable
              />

              <label className="form-label mt-2">Sub ccounty</label>
              <Select
                options={subCounties}
                onChange={(selected) =>
                  handleFilterChange(selected, "sub_county_id")
                }
                isClearable
              />

              <button className="btn btn-danger mt-3" onClick={clearFilters}>
                Clear Filters
              </button>
            </div>
          </div>
        </div>
      </section>

      <div className="row">
        <div className="col-md-12 px-4 d-flex justify-content-center">
          <div className="card shadow-sm border-0 ">
            <div className="card-header bg-light text-dark">
              <h5 className="mb-0 text-center">
                <i className="bx bx-sitemap"></i> Uganda Master Health Facility
                List
              </h5>
            </div>
            <div className="card-body">
              <CustomTable
                data={facilities}
                columns={columns}
                itemsPerPage={30}
                onRowClick={handleFacilityClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Facilities;
