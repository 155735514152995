import React from "react";
import "./footer.css"; // Custom styles

const Footer = () => {
    return (
        <footer className="footer bg-light text-center py-3">
            <div className="container d-flex justify-content-between">
                <p className="mb-0">&copy; 2025 Ministry of Health. All Rights Reserved.</p>
                <p className="mb-0">Version: <a href="#">1.0</a></p>
            </div>
        </footer>
    );
};

export default Footer;
