/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import API from "../../../../helpers/api";
import Layout from "../layout";

const Master = () => {
  const [facilities, setFacilities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    fetchAdminUnits();
  }, []);

  const fetchAdminUnits = async () => {
    try {
      const response = await API.get("/facility");
      setFacilities(response.data.facilities);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch data");
      setLoading(false);
    }
  };

  const handleFacilityClick = (id, name) => {
    history.push({
      pathname: `/d/admin/facility/masterlist/${id}`,
      state: { facilityName: name },
    });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p style={{ color: "red" }}>{error}</p>;

  return (
    <Layout>
      {" "}
      <div className="container-fluid mb-3">
        <div className="row gap-5">
          {/* <div className="col-md-2">
            <SideBar />
        </div> */}
          <div className="col-md-12 px-4 d-flex justify-content-center">
            <div className="card shadow-sm border-0 ">
              <div className="card-header bg-light text-dark">
                <h5 className="mb-0 text-center">
                  <i className="bx bx-sitemap"></i> Uganda Master Facility List
                </h5>
              </div>
              <div className="card-body">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Unique Identifier</th>
                      <th>Facility Name</th>
                      <th>Level</th>
                      <th>Ownership</th>
                      <th>Authority</th>
                      <th>SubCounty</th>
                      <th>Local Govt</th>
                      <th>Region</th>
                    </tr>
                  </thead>
                  <tbody>
                    {facilities &&
                      facilities.map((unit) => (
                        <tr key={unit.id}>
                          <td>
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFacilityClick(unit.id, unit.facilityName);
                              }}
                              style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                                color: "blue",
                              }}
                            >
                              {unit.uniqueIdentifier}
                            </a>
                          </td>
                          <td>{unit.facilityName}</td>
                          <td>{unit.levelOfCare}</td>
                          <td>{unit.ownership}</td>
                          <td>{unit.authority}</td>
                          <td>{unit.SubCounty && unit.SubCounty.name}</td>
                          <td>{unit.LocalGovt && unit.LocalGovt.name}</td>
                          <td>{unit.Region && unit.Region.name}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Master;
