import React, { useState } from "react";
import { toast } from "react-toastify";
import API from "../../../helpers/api";
import FNSpinner from '../../../components/FNSpinner';

const Reject = ({ close, id, facility }) => {
    const [loading, setLoading] = useState(false);
    const [comments, setComments] = useState("");

    const token = localStorage.getItem("token");

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            status: 'district_rejected',
            comments,
            requestId: id,
            rejected: true,
            createdBy: facility.requested_by
        };

        try {
            await API.put(`/requests/${id}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setLoading(false);
            close();
            window.location.reload();
            toast.success("Health Facility Addition Rejected");
        } catch {
            setLoading(false);
            toast.error("Error Encountered While Rejecting Health Facility");
        }
    };

    return (
        <div className="card">
            {/* Grey Card Header */}
            <div className="card-header bg-secondary text-white fw-bold">
                Reject Health Facility Addition
            </div>

            <div className="card-body">
                <div className="mb-3">
                    <label className="form-label fw-semibold">Rejection Comment</label>
                    <textarea
                        className="form-control"
                        placeholder="Enter rejection reason..."
                        value={comments}
                        rows={4}
                        onChange={(e) => setComments(e.target.value)}
                    />
                </div>

                {/* Buttons aligned properly */}
                <div className="d-flex justify-content-end gap-2 mt-3">
                    <button className="btn btn-danger" onClick={handleSubmit}>
                        {loading ? <FNSpinner /> : "Reject"}
                    </button>
                    <button className="btn btn-outline-secondary" onClick={close}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default Reject;