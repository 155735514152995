import React from "react";

const Classification = ({ formData, handleChange }) => {
  return (
    <div className="row">
      <div className="col-10 mx-auto">
        <h5 className="mb-4">Health Facility Classification</h5>
        <div className="row">
          <div className="col-6">
            <div class="mb-3">
              <select
                class="form-select custom-select"
                id="facilityType"
                aria-label="Select Level of Care"
                value={formData.leve}
                onChange={handleChange}
                name="level"
              >
                <option value="" disabled selected>
                  -- Select Level of Care --
                </option>
                <option value="BCDP">BCDP</option>
                <option value="Clinic">Clinic</option>
                <option value="Drug Shop">Drug Shop</option>
                <option value="General Hospital">General Hospital</option>
                <option value="HC II">HC II</option>
                <option value="HC III">HC III</option>
                <option value="HC IV">HC IV</option>
                <option value="NRH">NRH</option>
                <option value="RBB">RBB</option>
                <option value="RRH">RRH</option>
                <option value="Specialized Hospital">
                  Specialized Hospital
                </option>{" "}
              </select>
            </div>
            <div class="mb-3">
              <select
                class="form-select custom-select"
                id="ownershipType"
                aria-label="Select Ownership Type"
                value={formData.ownership}
                onChange={handleChange}
                name="ownership"
              >
                <option value="" disabled selected>
                  -- Select Ownership --
                </option>
                {/* <option value="Public">Public</option> */}
                <option value="GOV">GOV</option>
                <option value="PFP">PFP</option>
                <option value="PNFP">PNFP </option>
              </select>
            </div>
          </div>
          <div className="col-6">
            <div class="mb-3">
              <select
                class="form-select custom-select"
                id="organizationType"
                aria-label="Select Health Facility Authority"
                value={formData.authority}
                onChange={handleChange}
                name="authority"
              >
                <option value="" disabled selected>
                  -- Select Facility Authority --
                </option>
               

                {[
                  "BCDP",
                  "Clinic",
                  "Drug Shop",
                  "General Hospital",
                  "HC II",
                  "HC III",
                  "HC IV",
                  "NRH",
                  "RBB",
                  "RRH",
                  "Specialized Hospital",
                  "BOU",
                  "GOV",
                  "LG",
                  "MoES",
                  "MoH",
                  "MOH",
                  "NGO",
                  "Private",
                  "PRIVATE",
                  "SDA",
                  "SOS",
                  "UCBHCA",
                  "UCMB",
                  "UMMB",
                  "UNHCR",
                  "UOMB",
                  "UPDF",
                  "UPF",
                  "UPMB",
                  "UPS",
                ].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div class="mb-3">
              <select
                class="form-select custom-select"
                id="licenseStatus"
                aria-label="Select License Status"
                value={formData.license_status}
                onChange={handleChange}
                name="license_status"
              >
                <option value="" disabled selected>
                  -- Select License Status --
                </option>
                <option value="Licenced">Licenced</option>
                <option value="License Expired">License Expired</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Classification;
