import React, { useState } from "react";

const Documents = ({ handleFileChange }) => {
    const [selectedFiles, setSelectedFiles] = useState({
        operating_license: null,
        council_minutes: null,
        district_letter: null,
    });

    const handleFileSelect = (e) => {
        const { name, files } = e.target;
        const file = files[0];

        if (file) {

            // Update state with selected file name
            setSelectedFiles((prev) => ({ ...prev, [name]: file.name }));

            // Pass file to parent component
            handleFileChange(e);
        }
    };

    return (
        <div>
            <div className="row mt-4">
                <div className="col-10 mx-auto">
                    <h5 className="mb-4">Upload Supporting Documents</h5>

                    <div className="mb-3">
                        <label className="form-label">Operating License*</label>
                        <input
                            type="file"
                            name="operating_license"
                            className="form-control custom-file-input"
                            onChange={handleFileSelect}
                        />
                        {selectedFiles.operating_license && (
                            <small className="text-success">Selected: {selectedFiles.operating_license}</small>
                        )}
                    </div>

                  

                    <div className="mb-3">
                        <label className="form-label">District Letter*</label>
                        <input
                            type="file"
                            name="district_letter"
                            className="form-control custom-file-input"
                            onChange={handleFileSelect}
                        />
                        {selectedFiles.district_letter && (
                            <small className="text-success">Selected: {selectedFiles.district_letter}</small>
                        )}
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Council Minutes(optional)</label>
                        <input
                            type="file"
                            name="council_minutes"
                            className="form-control custom-file-input"
                            onChange={handleFileSelect}
                        />
                        {selectedFiles.council_minutes && (
                            <small className="text-success">Selected: {selectedFiles.council_minutes}</small>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Documents;
