import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../../../helpers/api";
import FNModal from "../../FNModal";
import Approve from "./Approve";
import Reject from "./Reject";


const FacilityDetails = () => {
  const { id } = useParams();
  const [facility, setFacility] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);

  useEffect(() => {
    fetchFacilityDetails();
  }, []);

  const fetchFacilityDetails = async () => {
    try {
      const response = await API.get(`/requests/${id}`);
      setFacility(response.data);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch facility details.");
      setLoading(false);
    }
  };

  if (loading) return <p>Loading facility details...</p>;
  if (error) return <p style={{ color: "red" }}>{error}</p>;

  return (
    <div className="container mb-3">
      <FNModal
        showModal={showModal}
        handleClose={() => setShowModal(false)}
        lg="lg"
        title="District New Health Facility Addition Approval"
      >
        <Approve
          close={() => setShowModal(false)}
          facility={facility}
          id={id}
        />
      </FNModal>
      <FNModal
        showModal={rejectModal}
        handleClose={() => setRejectModal(false)}
        lg="lg"
        title="District New Health Facility Addition Rejection"
      >
        <Reject
          close={() => setRejectModal(false)}
          facility={facility}
          id={id}
        />
      </FNModal>
      <div className="row gap-5">
        <div className="col-12">
          <div className="card shadow-sm border-0">
            <div className="card-header bg-light text-dark">
              <h4 className="mb-0 text-center">
                <i className="bx bx-sitemap"></i> {facility.facilityName}{" "}
                Facility Details
              </h4>
            </div>
            <div className="card-body">
              {/* Header with Approve and Reject Buttons */}
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5>Facility Addition New Request</h5>
                <div className="d-flex align-items-center gap-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => setShowModal(true)}
                  >
                    Approve
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => setRejectModal(true)}
                  >
                    Reject
                  </button>
                </div>
              </div>

              {/* Basic Information Card */}
              <div className="card mb-3">
                <div className="card-header bg-light fw-bold">
                  Basic Information
                </div>
                <div className="card-body">
                  <div className="row">
                    {[
                      ["Request ID", facility.id],
                      ["Facility Name", facility.name],
                      ["Level of Care", facility.level],
                      ["Ownership", facility.ownership],
                      ["Authority", facility.authority],
                      ["Operational Status", facility.operational_status],
                      ["License", facility.license_status],
                      ["Contact Person Name", facility.contact_person_name],
                      ["Contact Person Name", facility.contact_person_title],
                      ["Contact Person Email", facility.contact_person_email],
                      ["Contact Person Mobile", facility.contact_person_mobile],
                    ].map(([label, value], index) => (
                      <div key={index} className="col-6 col-md-4">
                        <p>
                          <strong>{label}:</strong> {value}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Location Information Card */}
              <div className="card mb-3">
                <div className="card-header bg-light fw-bold">
                  Location Information
                </div>
                <div className="card-body">
                  <div className="row">
                    {[
                      ["Region", facility.Region.name],
                      ["District", facility.District.name],
                      ["Subcounty", facility.SubCounty.name],
                      ["Address", facility.physical_location],
                      ["Latitude", facility.latitude],
                      ["Longitude", facility.longitude],
                      ["Bed Capacity", facility.beds],
                    ].map(([label, value], index) => (
                      <div key={index} className="col-6 col-md-4">
                        <p>
                          <strong>{label}:</strong> {value}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Supporting Documents Card */}
              <div className="card mb-3">
                <div className="card-header bg-light fw-bold">
                  Supporting Documents
                </div>
                <div className="card-body">
                  <div className="row">
                    {[
                      ["Operating License", facility.operating_license],
                      ["Council Minutes", facility.council_minutes],
                      ["District Letter", facility.district_letter],
                    ].map(([label, filePath], index) => (
                      <div
                        key={index}
                        className="col-6 col-md-4 d-flex align-items-center"
                      >
                        <strong className="me-2">{label}:</strong>
                        {filePath ? (
                          <a
                            href={`http://localhost:9090/${filePath.replace(
                              /\\/g,
                              "/"
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-warning d-flex align-items-center"
                          >
                            <i className="bx bxs-file-pdf me-1 fs-5"></i>{" "}
                            {/* Boxicons PDF icon */}
                            View
                          </a>
                        ) : (
                          <span className="text-danger">Not Available</span>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacilityDetails;
