/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import API from "../../helpers/api";
import CustomTable from "../reusable/CustomTable";

const StatusTracking = () => {
  const [approvals, setApprovals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    fetchAdminUnits();
  }, []);

  const fetchAdminUnits = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await API.get("/approval", {
        headers: { Authorization: `Bearer ${token}` },
      });

      setApprovals(response.data.approvals || []);
    } catch (err) {
      setError(err.message || "Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  const handleFacilityClick = (id, name) => {
    history.push({
      pathname: `/d/request/details/${id}`,
      state: { facilityName: name },
    });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p style={{ color: "red" }}>{error}</p>;

  const columns = [
    { header: "Facility Name", accessor: (unit) => unit.Request?.name || "No name" },
    { header: "Request Type", accessor: (unit) => unit.Request?.request_type || "N/A" },
    { header: "Comments", accessor: "comments" },
    { header: "Status", accessor: "status" },
    {
      header: "Date",
      accessor: "createdAt",
      render: (unit) => unit.createdAt ? new Date(unit.createdAt).toLocaleDateString() : "N/A"
    },
  ];


  return (
    <div className="container">
      <div className="row ">
        <div className="col-12">
          <div class="card">
            <div class="card-header">
              <h6 class="slim-card-title">Request Status Tracking</h6>
            </div>
            {approvals.length === 0 ? (
              <p className="text-center text-muted">
                No Facility Requests Trackling Yet.
              </p>
            ) : (
              <>
                <CustomTable
                  data={approvals}
                  columns={columns}
                  itemsPerPage={30}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusTracking;
