import React, { useState, useEffect } from "react";
import API from "../../../helpers/api";
import CustomDash from "./CustomDash";
import "./landing.css";

const LandingPage = () => {
  const [ownership, setOwnership] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [region, setRegion] = useState([]);
  const [facilityCounts, setFacilityCounts] = useState([]);

  const loadRegionStats = async () => {
    setLoading(true);
    try {
      const res = await API.get(`/facility/region`);
      setRegion(res.data);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const loadOwnershipStats = async () => {
    setLoading(true);
    try {
      const res = await API.get(`/facility/ownership`);
      setOwnership(res.data);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const fetchFacilityCounts = async () => {
    try {
      const response = await API.get("/facility/stats");
      setFacilityCounts(response.data.data);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching facility counts:", err);
      setError("Failed to load data");
      setLoading(false);
    }
  };

  useEffect(() => {
    loadRegionStats();
    loadOwnershipStats();
    fetchFacilityCounts();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p style={{ color: "red" }}>{error}</p>;

  const totalFacilities = facilityCounts.reduce(
    (acc, facility) => acc + parseInt(facility.count, 10),
    0
  );

  // Define unique Boxicons for different ownership types
  const facilityIcons = {
    GOV: "bxs-bank",
    PNFP: "bxs-church",
    PFP: "bxs-clinic",
    Other: "bxs-clinic", // Default fallback icon
  };

  return (
    <div className="container">
      <section className="text-center1">
        <div className="card">
          <h4>Uganda National Health Facility Registry</h4>
          <p className="lead1">
            Welcome to the Uganda Master Health Facility Registry. The Registry
            is a complete listing of both public and private health facilities
            in the country. There are <b>{totalFacilities.toLocaleString()} </b>
            health facilities and each are established under unique
            administrative units i.e. Region, district, health sub-district, and
            subcounty etc. The need to uniqely identify health facilities in the
            country is of paramount importance for better health service
            delivery
          </p>
        </div>
      </section>

      <section>
        <div className="row text-center mt-4">
          {/* Total Facilities Card */}
          <div className="col-lg-3 col-sm-6">
            <div className="services p-2 text-center card-1">
              <i className="bx bxs-building-house display-4 mb-2"></i>
              <h5>Total Health Facilities</h5>
              <h1 className="fw-bold">{totalFacilities.toLocaleString()}</h1>
            </div>
          </div>

          {/* Facility Type Cards */}
          {facilityCounts.map((facility, index) => {
            const icon =
              facilityIcons[facility.ownership] || facilityIcons["Other"];
            return (
              <div key={index} className="col-lg-3 col-sm-6">
                <div className={`services p-2 text-center card-${index + 2}`}>
                  <i className={`bx ${icon} display-4 mb-2`}></i>
                  <h5>{facility.ownership}</h5>
                  <h1 className="fw-bold">
                    {parseInt(facility.count, 10).toLocaleString()}
                  </h1>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      <section className="mt-4">
        <div className="card">
          <div className="row">
            <div className="col-12">
              <h5 className="text-center">
                Summary of Health Health Facilities By Level and Ownership
              </h5>
              <div className="container mt-4">
                <div className="table-responsive">
                  <table className="table table-bordered table-striped">
                    <thead className="table-dark">
                      <tr>
                        <th>Facility Level</th>
                        <th>Government</th>
                        <th>Private For Profit</th>
                        <th>Private Not For Profit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(ownership || []).length > 0 ? (
                        ownership.map((row, index) => (
                          <tr key={index}>
                            <td>{row.level}</td>
                            <td>{row["GOV"] || 0}</td>
                            <td>{row["PFP"] || 0}</td>
                            <td>{row["PNFP"] || 0}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="text-center">No data available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-4">
        <div className="card">
          <div className="row">
            <div className="col-12">
              <h5 className="text-center">
                Summary of Health Facilities by Health Sub Regions
              </h5>
              <div className="container mt-4">
                <div className="table-responsive">
                  <table className="table table-bordered table-striped">
                    <thead className="table-dark">
                      <tr>
                        <th>Region</th>
                        <th>Clinic</th>
                        <th>HC II</th>
                        <th>HC III</th>
                        <th>HC IV</th>
                        <th>General Hospital</th>
                        <th>RRH</th>
                        <th>NRH</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(region || []).length > 0 ? (
                        region.map((row, index) => (
                          <tr key={index}>
                            <td>{row.region}</td>
                            <td>{row["Clinic"] || 0}</td>
                            <td>{row["HC II"] || 0}</td>
                            <td>{row["HC III"] || 0}</td>
                            <td>{row["HC IV"] || 0}</td>
                            <td>{row["General Hospital"] || 0}</td>
                            <td>{row.RRH || 0}</td>
                            <td>{row["NRH"] || 0}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="text-center">No data available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    
  );
};

export default LandingPage;
