import React from "react";
import CustomSideBar from "../../../components/reusable/CustomSideBar";
import { Building2, Edit, XCircle, User, List } from "lucide-react";

function Layout({ children, heading }) {

 

const tabs = [
  {
    path: "/d/admin/add",
    key: "addition",
    icon: Building2,
    label: "Direct Addition",
  },
  {
    path: "/d/admin/update",
    key: "update",
    icon: Edit,
    label: "Direct Update",
  },
  {
    path: "/d/admin/deactivate",
    key: "deactivate",
    icon: XCircle,
    label: "Direct Deactivation",
  },
  {
    path: "/d/admin/status",
    key: "status",
    icon: User,
    label: "Request Tracking",
  },
  {
    path: "/d/admin/masterlist",
    key: "requests",
    icon: List,
    label: "Master Facility List",
  },
];

  // const tabs = [

  //   {
  //     path: "/d/admin/add",
  //     key: "addition",
  //     icon: "bx-buildings",
  //     label: "Direct Addition",
  //   },
  //   {
  //     path: "/d/admin/update",
  //     key: "update",
  //     icon: "bx-edit",
  //     label: "Direct Update",
  //   },

  //   {
  //     path: "/d/admin/deactivate",
  //     key: "deactivate",
  //     icon: " bx-x-circle",
  //     label: "Direct Deactivatation",
  //   },
  //   {
  //     path: "/d/admin/status",
  //     key: "status",
  //     icon: "bx-user",
  //     label: "Request Tracking",
  //   },

  //   {
  //     path: "/d/admin/masterlist",
  //     key: "requests",
  //     icon: "bx-list-ul",
  //     label: "Master Facility List",
  //   }
  // ];

  return (
    <>
      <div className="container mb-3">
        <div className="row gap-5 mt-5">
          <div className="col-2">
            <CustomSideBar tabs={tabs} heading={heading} />
          </div>
          <div className="col-9">{children}</div>
        </div>
      </div>
    </>
  );
}

export default Layout;
