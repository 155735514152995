"use client"

import { useState, useEffect } from "react"
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from "lucide-react"

function CustomTable({ data, columns, itemsPerPage = 30, onRowClick }) {
  const [currentPage, setCurrentPage] = useState(1)
  const [currentItems, setCurrentItems] = useState([])
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    if (data && data.length > 0) {
      const indexOfLastItem = currentPage * itemsPerPage
      const indexOfFirstItem = indexOfLastItem - itemsPerPage
      setCurrentItems(data.slice(indexOfFirstItem, indexOfLastItem))
      setTotalPages(Math.ceil(data.length / itemsPerPage))
    } else {
      setCurrentItems([])
      setTotalPages(0)
    }
  }, [data, currentPage, itemsPerPage])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const renderPaginationItem = (page, label, icon) => (
    <li className={`page-item ${currentPage === page ? "active" : ""} ${page === "..." ? "disabled" : ""}`}>
      <button
        className="page-link"
        onClick={() => handlePageChange(page)}
        disabled={page === "..." || currentPage === page}
      >
        {icon} {label}
      </button>
    </li>
  )

  const renderPagination = () => {
    const pageNumbers = []
    const ellipsis = (
      <li className="page-item disabled bg-warning">
        <span className="page-link">...</span>
      </li>
    )

    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(renderPaginationItem(i, i))
      }
    } else {
      pageNumbers.push(renderPaginationItem(1, 1))
      if (currentPage > 3) pageNumbers.push(ellipsis)

      let start = Math.max(2, currentPage - 1)
      let end = Math.min(totalPages - 1, currentPage + 1)

      if (currentPage === totalPages) start = totalPages - 2
      if (currentPage === 1) end = 3

      for (let i = start; i <= end; i++) {
        pageNumbers.push(renderPaginationItem(i, i))
      }

      if (currentPage < totalPages - 2) pageNumbers.push(ellipsis)
      pageNumbers.push(renderPaginationItem(totalPages, totalPages))
    }

    return (
      <nav aria-label="Table pagination">
        <ul className="pagination justify-content-center">
          {renderPaginationItem(1, "", <ChevronsLeft size={16} />)}
          {renderPaginationItem(Math.max(1, currentPage - 1), "", <ChevronLeft size={16} />)}
          {pageNumbers}
          {renderPaginationItem(Math.min(totalPages, currentPage + 1), "", <ChevronRight size={16} />)}
          {renderPaginationItem(totalPages, "", <ChevronsRight size={16} />)}
        </ul>
      </nav>
    )
  }

  if (!data || data.length === 0) {
    return <p className="text-center text-muted">You have No Approved Facility Requests Currently.</p>
  }

  return (
    <div className="container mt-4">
      <div className="table-responsive">
        <table className="table table-striped table-hover">
          <thead className="table-dark">
            <tr>
              {columns.map((column, index) => (
                <th key={index} scope="col">
                  {column.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item) => (
              <tr
                key={item.id}
                onClick={() => onRowClick && onRowClick(item)}
                className={onRowClick ? "table-active" : ""}
              >
                {columns.map((column, index) => (
                  <td key={index}>
                    {column.render
                      ? column.render(item)
                      : typeof column.accessor === "function"
                        ? column.accessor(item)
                        : item[column.accessor]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="w-100  px-2 d-flex align-items-center justify-content-end">{totalPages > 1 && renderPagination()}</div>
    </div>
  )
}

export default CustomTable





// import { useState, useEffect } from "react";
// import { Table, Pagination } from "@/components/ui";
// import { ChevronLeft, ChevronRight } from "lucide-react";

// function CustomTable({
//   data,
//   columns,
//   itemsPerPage = 30,
//   onRowClick,
// }) {
//   const [currentPage, setCurrentPage] = useState(1);
//   const [currentItems, setCurrentItems] = useState([]);
//   const [totalPages, setTotalPages] = useState(0);

//   useEffect(() => {
//     if (data && data.length > 0) {
//       const indexOfLastItem = currentPage * itemsPerPage;
//       const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//       setCurrentItems(data.slice(indexOfFirstItem, indexOfLastItem));
//       setTotalPages(Math.ceil(data.length / itemsPerPage));
//     } else {
//       setCurrentItems([]);
//       setTotalPages(0);
//     }
//   }, [data, currentPage, itemsPerPage]);

//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//   };

//   if (!data || data.length === 0) {
//     return <div>No data available.</div>;
//   }

//   return (
//     <div className="space-y-4">
//       <Table>
//         <Table.Header>
//           <Table.Row>
//             {columns.map((column, index) => (
//               <Table.Head key={index}>{column.header}</Table.Head>
//             ))}
//           </Table.Row>
//         </Table.Header>
//         <Table.Body>
//           {currentItems.map((item) => (
//             <Table.Row
//               key={item.id}
//               onClick={() => onRowClick && onRowClick(item)}
//               className={onRowClick ? "cursor-pointer hover:bg-gray-50" : ""}
//             >
//               {columns.map((column, index) => (
//                 <Table.Cell key={index}>
//                   {column.render
//                     ? column.render(item)
//                     : typeof column.accessor === "function"
//                     ? column.accessor(item)
//                     : item[column.accessor]}
//                 </Table.Cell>
//               ))}
//             </Table.Row>
//           ))}
//         </Table.Body>
//       </Table>

//       {totalPages > 1 && (
//         <Pagination className="justify-center">
//           <Pagination.Content>
//             <Pagination.Item>
//               <Pagination.Previous
//                 onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
//                 disabled={currentPage === 1}
//               >
//                 <ChevronLeft className="h-4 w-4" />
//                 <span className="sr-only">Previous page</span>
//               </Pagination.Previous>
//             </Pagination.Item>
//             {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
//               <Pagination.Item key={page}>
//                 <Pagination.Link
//                   onClick={() => handlePageChange(page)}
//                   isActive={currentPage === page}
//                 >
//                   {page}
//                 </Pagination.Link>
//               </Pagination.Item>
//             ))}
//             <Pagination.Item>
//               <Pagination.Next
//                 onClick={() =>
//                   handlePageChange(Math.min(totalPages, currentPage + 1))
//                 }
//                 disabled={currentPage === totalPages}
//               >
//                 <ChevronRight className="h-4 w-4" />
//                 <span className="sr-only">Next page</span>
//               </Pagination.Next>
//             </Pagination.Item>
//           </Pagination.Content>
//         </Pagination>
//       )}
//     </div>
//   );
// }

// export default CustomTable;
