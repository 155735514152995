import React, { useState, useEffect } from 'react';
import API from '../../../helpers/api';

const Identification = ({ formData, handleChange }) => {
    const [regions, setRegions] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [subCounties, setSubCounties] = useState([]);
    const [facilities, setFacilities] = useState([]);

    useEffect(() => {
        fetchRegions();
    }, []);

    const fetchRegions = async () => {
        try {
            const response = await API.get('/adminareas/regions');
            setRegions(response.data);
        } catch (error) {
            console.error("Error fetching regions:", error);
        }
    };

    const handleRegionChange = async (e) => {
        const region_id = e.target.value;
        handleChange(e);

        if (!region_id) return;
        try {
            const response = await API.get(`/adminareas/district?region_id=${region_id}`);
            setDistricts(response.data);
            setSubCounties([]);
            setFacilities([]);
        } catch (error) {
            console.error("Error fetching local governments:", error);
        }
    };

    const handleDistrictChange = async (e) => {
        const district_id = e.target.value;
        handleChange(e);

        if (!district_id) return;
        try {
            const response = await API.get(`/adminareas/subcounty?district_id=${district_id}`);
            setSubCounties(response.data);
            setFacilities([]);
        } catch (error) {
            console.error("Error fetching sub-counties:", error);
        }
    };

    const handleSubCountyChange = async (e) => {
        const sub_county_id = e.target.value;
        handleChange(e);

        if (!sub_county_id) return;
        try {
            const response = await API.get(`/adminareas/facility?sub_county_id=${sub_county_id}`);
            setFacilities(response.data);
        } catch (error) {
            console.error("Error fetching facilities:", error);
        }
    };

    return (
        <div className="row">
            <div className="col-10 mx-auto">
                <h5 className="mb-4">License Information</h5>
                <div className="row">
                    <div className="col-6">
                        <input
                            type="text"
                            name="name"
                            className="form-control mb-3"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Health Facility Name"
                        />
                        <input
                            type="date"
                            name="date_registered"
                            className="form-control mb-3"
                            value={formData.date_registered}
                            onChange={handleChange}
                            placeholder="Date Of Registration)"
                        />
                    </div>

                    <div className="col-6">
                        <div className="mb-3">
                            <input
                                type="text"
                                name="license_number"
                                className="form-control mb-3"
                                value={formData.license_number}
                                onChange={handleChange}
                                placeholder="License Number"
                            />
                        </div>

                        {/* SubCounty Dropdown */}
                        <div className="mb-3">
                            <select
                                name="regulatory_body"
                                className="form-select"
                                value={formData.regulatory_body}
                                onChange={handleRegionChange}
                            >
                                <option value="">Select Regulatory Body</option>
                                <option value='Uganda Medical and Dental Practitioners Council'>
                                    Uganda Medical and Dental Practitioners Counci
                                </option>
                                <option value='Uganda Medical and Dental Practitioners Council'>
                                    Uganda Allied Health Professionals Council
                                </option> <option value='Uganda Medical and Dental Practitioners Council'>
                                    Uganda Nurses and Midwives Council
                                </option> <option value='Uganda Medical and Dental Practitioners Council'>
                                    Pharmacy Board / National Drug Authority
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className='py-2'>
                    <h6 className="mb-3">Location & Administrative Area</h6>
                    <div className="row">
                        <div className="col-6">
                            <div className="mb-3">
                                <select
                                    name="region_id"
                                    className="form-select"
                                    value={formData.region_id}
                                    onChange={handleRegionChange}
                                >
                                    <option value="">Select Region</option>
                                    {regions.map(region => (
                                        <option key={region.id} value={region.id}>
                                            {region.name}
                                        </option>
                                    ))}
                                </select>
                                <select
                                    name="sub_county_id"
                                    className="form-select mt-3"
                                    value={formData.sub_county_id}
                                    onChange={handleSubCountyChange}
                                    disabled={!formData.district_id}
                                >
                                    <option value="">Choose Subcounty / Division</option>
                                    {subCounties.map(sub => (
                                        <option key={sub.id} value={sub.id}>
                                            {sub.name}
                                        </option>
                                    ))}
                                </select>
                                <input
                                    type="text"
                                    name="latitude"
                                    value={formData.latitude}
                                    onChange={handleChange}
                                    className="form-control mt-3"
                                    placeholder="Latitude"
                                />
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="mb-3">
                                <select
                                    name="district_id"
                                    className="form-select"
                                    value={formData.district_id}
                                    onChange={handleDistrictChange}
                                    disabled={!formData.region_id}
                                >
                                    <option value="">Select District</option>
                                    {districts.map(lg => (
                                        <option key={lg.id} value={lg.id}>
                                            {lg.name}
                                        </option>
                                    ))}
                                </select>
                                <input
                                    type="text"
                                    name="physical_location"
                                    value={formData.physical_location}
                                    onChange={handleChange}
                                    className="form-control mt-3"
                                    placeholder="Pysical Address"
                                />
                                <input
                                    type="text"
                                    name="longitude"
                                    value={formData.longitude}
                                    onChange={handleChange}
                                    className="form-control mt-3"
                                    placeholder="Longtitude"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Identification;