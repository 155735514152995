import React, { useState, useEffect } from 'react';
import API from "../../../../helpers/api";
import Layout from '../layout';

const Services = () => {
    const [levelOfCare, setLevelOfCare] = useState("National Referral Hospital");
    const [services, setServices] = useState([]);
    const [newService, setNewService] = useState({ service_name: '', description: '' });
    const [editService, setEditService] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchServices();
    }, [levelOfCare]);

    const fetchServices = async () => {
        try {
            setLoading(true);
            const response = await API.get(`/services/${levelOfCare}`);
            setServices(response.data);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch health services');
            setLoading(false);
        }
    };

    const handleAddService = async () => {
        try {
            await API.post('/services', {
                level_of_care: levelOfCare,
                service_name: newService.service_name,
                description: newService.description
            });
            fetchServices();
            setNewService({ service_name: '', description: '' });
        } catch (err) {
            alert('Error adding service');
        }
    };

    const handleEditService = async () => {
        try {
            await API.put(`/services/${editService.id}`, editService);
            fetchServices();
            setEditService(null);
        } catch (err) {
            alert('Error updating service');
        }
    };

    const handleDeleteService = async (id) => {
        if (window.confirm('Are you sure you want to delete this service?')) {
            await API.delete(`/services/${id}`);
            fetchServices();
        }
    };

    return (
      <Layout>  <div className="container mt-4">
      <h2>Health Services Provided for {levelOfCare}</h2>

      <div className="mb-3">
          <label>Select Level of Care:</label>
          <select
              className="form-control"
              value={levelOfCare}
              onChange={(e) => setLevelOfCare(e.target.value)}
          >
              <option value="National Referral Hospital">National Referral Hospital</option>
              <option value="Regional Referral Hospital">Regional Referral Hospital</option>
              <option value="General Hospital">General Hospital</option>
              <option value="HC IV">HC IV</option>
              <option value="HC III">HC III</option>
              <option value="HC II">HC II</option>
              <option value="VHT">VHT</option>
          </select>
      </div>

      {loading ? <p>Loading...</p> : null}
      {error ? <p style={{ color: 'red' }}>{error}</p> : null}

      <h3>Add New Service</h3>
      <input
          type="text"
          placeholder="Service Name"
          className="form-control mb-2"
          value={newService.service_name}
          onChange={(e) => setNewService({ ...newService, service_name: e.target.value })}
      />
      <textarea
          placeholder="Description"
          className="form-control mb-2"
          value={newService.description}
          onChange={(e) => setNewService({ ...newService, description: e.target.value })}
      ></textarea>
      <button className="btn btn-primary mb-3" onClick={handleAddService}>Add Service</button>

      {editService && (
          <div className="mt-3">
              <h3>Edit Service</h3>
              <input
                  type="text"
                  value={editService.service_name}
                  onChange={(e) => setEditService({ ...editService, service_name: e.target.value })}
                  className="form-control mb-2"
              />
              <button className="btn btn-success mb-3" onClick={handleEditService}>Save Changes</button>
          </div>
      )}

      <table className="table table-bordered">
          <thead>
              <tr>
                  <th>ID</th>
                  <th>Service Name</th>
                  <th>Description</th>
                  <th>Actions</th>
              </tr>
          </thead>
          <tbody>
              {services.map(service => (
                  <tr key={service.id}>
                      <td>{service.id}</td>
                      <td>{service.service_name}</td>
                      <td>{service.description || 'N/A'}</td>
                      <td>
                          <button className="btn btn-warning btn-sm" onClick={() => setEditService(service)}>Edit</button>
                          <button className="btn btn-danger btn-sm ms-2" onClick={() => handleDeleteService(service.id)}>Delete</button>
                      </td>
                  </tr>
              ))}
          </tbody>
      </table>
  </div></Layout>
    );
};

export default Services;