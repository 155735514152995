import React from 'react'
import { Link, useLocation } from "react-router-dom";

const SideBar = () => {

    const location = useLocation();

    return (
        <div className="sidebar border rounded p-3 shadow-sm">
            <button className="btn btn-primary w-100 mb-3">
                <i className="bx bx-plus-circle"></i> Users Actions
            </button>
            <nav className="nav flex-column">
                {[
                    { to: "#", icon: "bx-list-ul", label: "Users" },
                    { to: "#", icon: "bx-buildings", label: "Roles" },
                    { to: "#", icon: "bx-edit", label: "Permissions" },
                    { to: "#", icon: "bx-edit", label: "Password Resets" }
                ].map((item, index) => (
                    <Link
                        key={index}
                        to={item.to}
                        className={`nav-link p-2 rounded mb-2 ${location.pathname === item.to ? "active-link" : "inactive-link"}`}
                    >
                        <i className={`bx ${item.icon} me-2`}></i> <span>{item.label}</span>
                    </Link>
                ))}
            </nav>
        </div>
    )
}

export default SideBar