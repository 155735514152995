/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import FNModal from '../../../components/FNModal';
import API from "../../../helpers/api";
import SideBar from './SideBar';
import Adduser from './AddUsers';

const Users = () => {
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [users, setUsers] = useState([]);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const loadUsers = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/users`);
            console.log(res);
            setUsers(res?.data.user);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        loadUsers();
    }, []);

    return (
        <div className="container mt-5 mb-3">
            <FNModal
                showModal={showModal}
                handleClose={handleClose}
                lg="lg"
                title="Add Facility Registry User"
            >
                <Adduser close={handleClose} refresh={loadUsers} />
            </FNModal>
            <div className="row gap-5">
                <div className="col-md-2">
                    <SideBar />
                </div>
                <div className="col-md-9 d-flex justify-content-center">
                    <div className="card shadow-sm border-0">
                        {/* Header Section with Title and Button on Same Line */}
                        <div className="card-header bg-light text-dark d-flex justify-content-between align-items-center">
                            <h5 className="mb-0">
                                <i className="bx bx-sitemap"></i> Health Facility Registry Users
                            </h5>
                            <button 
                                type="button" 
                                className="btn btn-primary waves-effect waves-light"
                                onClick={handleShow}
                            >
                                Add New User
                            </button>
                        </div>
                        {/* Table Section */}
                        <div className="card-body">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Username</th>
                                        <th>Firstname</th>
                                        <th>Lastname</th>
                                        <th>Role</th>
                                        <th>Phoneno</th>
                                        <th>Created Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map((unit) => (
                                        <tr key={unit.id}>
                                            <td>{unit.email}</td>
                                            <td>{unit.firstname}</td>
                                            <td>{unit.lastname}</td>
                                            <td>{unit.role}</td>
                                            <td>{unit.phoneNo}</td>
                                            <td>{unit.createdAt ? new Date(unit.createdAt).toLocaleDateString() : 'N/A'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Users;