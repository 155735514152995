import React, { useState, useEffect } from "react";
import API from "../../../../helpers/api";
import SideBar from "../SideBar";
import CustomSideBar from "../../../../components/reusable/CustomSideBar";

const Hierarchy = () => {
  const [hierarchies, setHierarchies] = useState([]);
  const [name, setName] = useState("");
  const [editingId, setEditingId] = useState(null);

  useEffect(() => {
    fetchHierarchies();
  }, []);

  const fetchHierarchies = async () => {
    const res = await API.get("/hierarchy");
    setHierarchies(res.data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editingId) {
      await API.put(`/hierarchy/${editingId}`, { name });
    } else {
      await API.post("/hierarchy", { name });
    }
    setName("");
    setEditingId(null);
    fetchHierarchies();
  };

  const handleEdit = (id, currentName) => {
    setName(currentName);
    setEditingId(id);
  };

  const handleDelete = async (id) => {
    await API.delete(`/hierarchy/${id}`);
    fetchHierarchies();
  };

  const tabs = [
    // { path: "#", icon: "bx-list-ul", label: "Hierarchy Actions" },
    { path: "/d/admin/hierarchy", icon: "bx-list-ul", label: "Hierarchy" },
    {
      path: "/d/admin/hierarchy/levels",
      icon: "bx-buildings",
      label: "Hierarchy Levels",
    },
    { path: "/d/admin/units", icon: "bx-edit", label: "Administrative Areas" },
  ];

  return (
    <div className="container mt-5 mb-3">
      <div className="row">
        <div className="col-md-2">
          <CustomSideBar tabs={tabs} heading="Hierachy Actions"/>
        </div>
        {/* Main Content - Centered */}
        <div className="col-md-10 d-flex justify-content-center">
          <div className="card shadow-sm border-0 w-75">
            {/* Light Grey Background for Title */}
            <div className="card-header bg-light text-dark">
              <h5 className="mb-0 text-center">
                <i className="bx bx-sitemap"></i> Administrative Units Hierarchy
              </h5>
            </div>

            <div className="card-body">
              <form className="mb-4" onSubmit={handleSubmit}>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control shadow-sm"
                    placeholder="Enter hierarchy name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <button type="submit" className="btn btn-primary">
                    {editingId ? (
                      <i className="bx bx-pencil"></i>
                    ) : (
                      <i className="bx bx-plus"></i>
                    )}{" "}
                    {editingId ? "Update" : "Add"}
                  </button>
                </div>
              </form>

              <ul className="list-group">
                {hierarchies.map((hierarchy) => (
                  <li
                    key={hierarchy.id}
                    className="list-group-item d-flex justify-content-between align-items-center"
                  >
                    <span>{hierarchy.name}</span>
                    <div className="d-flex">
                      <button
                        className="btn btn-warning btn-sm me-2"
                        onClick={() => handleEdit(hierarchy.id, hierarchy.name)}
                      >
                        <i className="bx bx-edit"></i>
                      </button>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => handleDelete(hierarchy.id)}
                      >
                        <i className="bx bx-trash"></i>
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hierarchy;
