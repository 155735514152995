import React, { useState } from 'react';
import API from '../../../helpers/api';
import { toast } from 'react-toastify';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleForgotPassword = async (e) => {
        e.preventDefault();

        if (!email.trim()) {
            toast.error("Please enter your email", { position: "top-center" });
            return;
        }

        setLoading(true);

        try {
            const response = await API.post('/users/forgotpassword', { email });
            toast.success(response.data.message, { position: "top-center" });
        } catch (error) {
            toast.error(error.response?.data.message || 'Error sending email', { position: "top-center" });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="d-flex flex-column vh-100">
            {/* Main Content */}
            <div className="container d-flex justify-content-center align-items-center">
                <div className="card shadow-lg border-0 rounded w-50">
                    <div className="card-header bg-secondary text-white text-center fw-bold">
                        Forgot Password
                    </div>
                    <div className="card-body">
                        <p className="text-center text-muted">
                            Enter your registered email to receive a password reset link.
                        </p>
                        <form onSubmit={handleForgotPassword}>
                            <div className="mb-3">
                                <label className="form-label fw-semibold">Email Address</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Enter your email"
                                    required
                                />
                            </div>
                            <button type="submit" className="btn btn-primary w-100" disabled={loading}>
                                {loading ? "Processing..." : "Send Reset Link"}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            </div>
    );
};

export default ForgotPassword;