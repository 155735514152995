/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import API from "../../../../helpers/api";
import Layout from '../layout';

const FacilityRequests = () => {
    const [facilities, setFacilities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const history = useHistory();

    useEffect(() => {
        FacilityRequests();
    }, []);

    const FacilityRequests = async () => {
        try {
            const response = await API.get('/requests');
            setFacilities(response.data.request);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch data');
            setLoading(false);
        }
    };

    const handleFacilityClick = (id, name) => {
        history.push({
            pathname: `/d/admin/facility/requests/${id}`,
            state: { facilityName: name }
        });
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p style={{ color: 'red' }}>{error}</p>;

    return (
        <Layout>
            <div className="container-fluid mb-3">
                <div className="row gap-5">
                    <div className="col-md-12 d-flex justify-content-center">
                        <div className="card shadow-sm border-0 ">
                            <div className="card-header bg-light text-dark">
                                <h5 className="mb-0 text-center">
                                    <i className="bx bx-sitemap"></i>Pending Approval Requests
                                </h5>
                            </div>
                            <div className="card-body">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Facility Name</th>
                                            <th>District</th>
                                            <th>Level of Care</th>
                                            <th>Ownership</th>
                                            <th>Status</th>
                                            <th>Requested Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {facilities.map((unit) => (
                                            <tr key={unit.id}>
                                                <td>
                                                    <a
                                                        href="#"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handleFacilityClick(unit.id, unit.name);
                                                        }}
                                                        style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
                                                    >
                                                        {unit.name}
                                                    </a>
                                                </td>
                                                <td>{unit.District.name}</td>
                                                <td>{unit.level}</td>
                                                <td>{unit.ownership}</td>
                                                <td>{unit.status}</td>
                                                <td>{unit.createdAt ? new Date(unit.createdAt).toLocaleDateString() : 'N/A'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default FacilityRequests;
