/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import API from "../../helpers/api";
import CustomTable from "../../components/reusable/CustomTable";

const DistrictFacilities = () => {
  const [facilities, setFacilities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    fetchAdminUnits();
  }, []);

  const fetchAdminUnits = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await API.get("/facility/districtFacilities", {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log("facilities----",response.data)
      setFacilities(response.data || []);
    } catch (err) {
      setError(err.message || "Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  const handleFacilityClick = (id, name) => {
    history.push({
      pathname: `/d/request/details/${id}`,
      state: { facilityName: name },
    });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="text-center text-muted">You have No Facility Requests Currently.</p>;

  const columns = [
    { header: "Facility Name", accessor: "name" },
    { header: "Level of Care", accessor: "level" },
    { header: "Ownership", accessor: "ownership" },
    // { header: "Authority", accessor: "authority" },
    { header: "Request Type", accessor: "request_type" },
    { header: "Status", accessor: "status" },
    {
      header: "Requested Date",
      accessor: "createdAt",
      render: (facility) =>
        facility.createdAt
          ? new Date(facility.createdAt).toLocaleDateString()
          : "N/A",
    },
    // {
    //   header: "Details",
    //   accessor: "id",
    //   render: (facility) => (
    //     <a
    //       href="#"
    //       onClick={(e) => {
    //         e.preventDefault();
    //         handleFacilityClick(facility.id, facility.name);
    //       }}
    //       className="btn btn-primary btn-sm"
    //     >
    //       View
    //     </a>
    //   ),
    // },
  ];

  return (
    <div className="container ">
      <div className="row ">
        <div className="col-12">
          <div class="card">
            <div class="card-header">
              <h6 class="slim-card-title">My District Facilities</h6>
            </div>
            <CustomTable
              data={facilities}
              columns={columns}
              itemsPerPage={30}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DistrictFacilities;
