import React, { useState, useEffect } from 'react';
import API from '../../helpers/api';
import { toast } from 'react-toastify';
import "./profile.css";
import SideBar from '../../components/Layout/Admin/SideBar';

const Profile = () => {
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phoneNo: '',
        health_facility: ''
    });

    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await API.get('/users/profile', {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setFormData(response.data);
            } catch (error) {
                toast.error("Error fetching profile data", { position: "top-center" });
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();
    }, []);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        setUpdating(true);

        try {
            const token = localStorage.getItem('token');
            const response = await API.put('/users/updateprofile', formData, {
                headers: { Authorization: `Bearer ${token}` }
            });
            toast.success(response.data.message, { position: "top-center" });
        } catch (error) {
            toast.error(error.response?.data.message || 'Error updating profile', { position: "top-center" });
        } finally {
            setUpdating(false);
        }
    };

    return (

        <div className="container">
            <div className="row mt-5">
                <div className="col-3">
                    <SideBar />
                </div>
                <div className="col-9">
                    <div className="col-lg-10 mx-auto">
                        <div className="profile-card p-4">
                            <h2 className="text-center mb-4">Update Profile</h2>

                            {loading ? (
                                <div className="text-center">
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            ) : (
                                <form onSubmit={handleUpdate}>
                                    <div className="mb-3">
                                        <label className="form-label">First Name</label>
                                        <input
                                            type="text"
                                            name="firstname"
                                            className="form-control"
                                            value={formData.firstname}
                                            onChange={handleChange}
                                            placeholder="Enter First Name"
                                            required
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Last Name</label>
                                        <input
                                            type="text"
                                            name="lastname"
                                            className="form-control"
                                            value={formData.lastname}
                                            onChange={handleChange}
                                            placeholder="Enter Last Name"
                                            required
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Email</label>
                                        <input
                                            type="email"
                                            name="email"
                                            className="form-control"
                                            value={formData.email}
                                            onChange={handleChange}
                                            placeholder="Enter Email"
                                            required
                                            disabled
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Phone Number</label>
                                        <input
                                            type="text"
                                            name="phoneNo"
                                            className="form-control"
                                            value={formData.phoneNo}
                                            onChange={handleChange}
                                            placeholder="Enter Phone Number"
                                            required
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Health Facility</label>
                                        <input
                                            type="text"
                                            name="health_facility"
                                            className="form-control"
                                            value={formData.health_facility}
                                            onChange={handleChange}
                                            placeholder="Enter Health Facility"
                                            required
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-primary w-100" disabled={updating}>
                                        {updating ? (
                                            <span>
                                                <span className="spinner-border spinner-border-sm me-2"></span> Updating...
                                            </span>
                                        ) : (
                                            "Update Profile"
                                        )}
                                    </button>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Profile;