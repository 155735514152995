import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from "../../../../helpers/api";
// import SideBar from '../SideBar';

const FacilityDetails = () => {
    const { id } = useParams();
    const [facility, setFacility] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchFacilityDetails();
    }, []);

    const fetchFacilityDetails = async () => {
        try {
            const response = await API.get(`/facility/${id}`);
            console.log("XXXX===>", response)
            setFacility(response.data);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch facility details.');
            setLoading(false);
        }
    };

    if (loading) return <p>Loading facility details...</p>;
    if (error) return <p style={{ color: 'red' }}>{error}</p>;

    return (
        <div className="container mt-5 mb-3">
            <div className="row gap-5">
                <div className="col-2">
                    {/* <SideBar /> */}
                </div>
                <div className="col-9">
                    <div className="card shadow-sm border-0">
                        <div className="card-header bg-light text-dark">
                            <h4 className="mb-0 text-center">
                                <i className="bx bx-sitemap"></i> {facility.name} Facility Details
                            </h4>
                        </div>
                        <div className="card-body">
                            {/* Header with Approve and Reject Buttons */}
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <h5>Health Facility Details</h5>
                            </div>

                            {/* Basic Information Card */}
                            <div className="card mb-3">
                                <div className="card-header bg-light fw-bold">Basic Information</div>
                                <div className="card-body">
                                    <div className="row">
                                        {[
                                            ["Unique Identifier", facility.uniqueIdentifier],
                                            ["DHIS2 Code", facility.dhis2Code],
                                            ["Facility Name", facility.name],
                                            ["Display Name", facility.displayName],
                                            ["Level of Care", facility.levelOfCare],
                                            ["Ownership", facility.ownership],
                                            ["Authority", facility.authority],
                                            ["Operational Status", facility.operationalStatus],
                                            ["License", facility.licenseStatus],
                                            ["Facility Incharge", facility.inchargeName],
                                            ["Mobile Number", facility.inchargeMobile],
                                            ["Email", facility.inchargeEmail]
                                        ].map(([label, value], index) => (
                                            <div key={index} className="col-6 col-md-4">
                                                <p><strong>{label}:</strong> {value}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {/* Location Information Card */}
                            <div className="card mb-3">
                                <div className="card-header bg-light fw-bold">Location Information</div>
                                <div className="card-body">
                                    <div className="row">
                                        {[
                                            ["Region", facility.Region.name],
                                            ["Local Government", facility.LocalGovt.name],
                                            ["Subcounty", facility.SubCounty.name],
                                            ["Address", facility.physicalLocation],
                                            ["Latitude", facility.latitude],
                                            ["Longitude", facility.longitude],
                                            ["Bed Capacity", facility.beds]
                                        ].map(([label, value], index) => (
                                            <div key={index} className="col-6 col-md-4">
                                                <p><strong>{label}:</strong> {value}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FacilityDetails;