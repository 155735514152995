import React, { Fragment } from "react";
import { Switch } from "react-router-dom";

import Users from "../pages/Admin/Users";
import ProtectedRoute from "../helpers/protected";
import AdminLayout from "../components/Layout/Admin";

//Requestor Public View
import RequestorProfile from "../pages/Requestor/Profile";
import RequestDetails from "../pages/Requestor/RequestDetails";
import ApprovedRequests from "../components/FNTables/ApprovedRequests";
import RejectedRequests from "../components/FNTables/RejectedRequests";
import FacilityAdditionPublic from "../components/FNForms/PublicAddition";
import FacilityUpdatePubic from "../components/FNForms/PublicUpdate";

//District Verifier View
import DistrictProfile from "../pages/District/Profile";
import DistrictDetails from "../pages/District/RequestDetails";
import DistrictApproved from "../pages/District/approvedRequests";
import DistrictRequests from "../pages/District/DistrictRequests";
import PendingDistrictApprovals from "../components/FNTables/PendingDistrictApprovals";
import FacilityAdditions from "../pages/District/Requests/Addition";

//Admin View
import Services from "../pages/Admin/Facility/Services";
import MasterList from "../pages/Admin/Facility/MasterList";
import Hierarchy from "../pages/Admin/Hirerachy/Hierarchy";
import AdminUnits from "../pages/Admin/Hirerachy/AdminUnits";
import HierarchyLevel from "../pages/Admin/Hirerachy/HierarchyLevel";
import FacilityRequests from "../pages/Admin/Facility/ApprovalRequests";
import MasterListDetails from "../pages/Admin/Facility/MasterList/FacilityDetails";
import FacilityRequestDetails from "../pages/Admin/Facility/ApprovalRequests/Details";
import FacilityRequestsMain from "../pages/Requestor";
import DistrictFacilityRequestsMain from "../pages/District";
import StatusTracking from "../components/FNTables/StatusTracking";
import MyRequests from "../components/FNTables/MyRequests";
import AdminFacilityRequestsMain from "../pages/Admin/Facility";
import DeactiveFacility from "../components/FNForms/DeactivateFacility";

import AdminFacilityAddition from "../pages/Admin/Facility/DirectRequests/DirectAddition";
import AdminFacilityUpdate from "../pages/Admin/Facility/DirectRequests/DirectUpdate";
import AdminFacilityStatus from "../pages/Admin/Facility/DirectRequests/RequestStatus";
import AdminFacilityDeactivation from "../pages/Admin/Facility/DirectRequests/DirectDeactivative";
import AdminApprovalRequests from "../pages/Admin/Facility/ApprovalRequests";

import Master from "../pages/Admin/Facility/MasterList";
import FacilityDetails from "../components/reusable/RequestDetails/FacilityDetails";
import DistrictFacilities from "../pages/District/DistrictFacilities";


const AdminRoutes = () => {
  return (
    <Fragment>
      <Switch>
        <AdminLayout>
          {/* Public View */}
          <ProtectedRoute
            exact
            path="/d/requestor/status"
            component={StatusTracking}
          />
          <ProtectedRoute
            exact
            path="/d/requestor/update"
            component={FacilityUpdatePubic}
          />
          <ProtectedRoute
            exact
            path="/d/requestor/profile"
            component={RequestorProfile}
          />
          <ProtectedRoute
            exact
            path="/d/request/details/:id"
            component={FacilityDetails}
          />
          <ProtectedRoute
            exact
            path="/d/requestor/add"
            component={FacilityAdditionPublic}
          />
          <ProtectedRoute
            exact
            path="/d/requestor/deactivate"
            component={DeactiveFacility}
          />
          <ProtectedRoute
            exact
            path="/d/requestor/myrequests"
            component={MyRequests}
          />
          <ProtectedRoute
            exact
            path="/d/requestor/approved"
            component={ApprovedRequests}
          />
          <ProtectedRoute
            exact
            path="/d/requestor/rejected"
            component={RejectedRequests}
          />

          <ProtectedRoute
            exact
            path="/d/requestor"
            component={FacilityRequestsMain}
          />

          {/* District View */}
          <ProtectedRoute
            exact
            path="/d/district"
            component={DistrictFacilityRequestsMain}
          />
          <ProtectedRoute
            exact
            path="/d/district/status"
            component={StatusTracking}
          />
          <ProtectedRoute
            exact
            path="/d/district/update"
            component={FacilityUpdatePubic}
          />
          <ProtectedRoute
            exact
            path="/d/district/profile"
            component={DistrictProfile}
          />
          <ProtectedRoute
            exact
            path="/d/district/details/:id"
            component={DistrictDetails}
          />
          <ProtectedRoute
            exact
            path="/d/district/add"
            component={FacilityAdditions}
          />
          <ProtectedRoute
            exact
            path="/d/district/pending/approvals"
            component={PendingDistrictApprovals}
          />
          <ProtectedRoute
            exact
            path="/d/district/deactivate"
            component={DeactiveFacility}
          />
          <ProtectedRoute
            exact
            path="/d/district/requests"
            component={DistrictRequests}
          />
               <ProtectedRoute
            exact
            path="/d/district/facilities"
            component={DistrictFacilities}
          />


          <ProtectedRoute
            exact
            path="/d/district/myrequests"
            component={MyRequests}
          />
          <ProtectedRoute
            exact
            path="/d/district/approved"
            component={DistrictApproved}
          />

          {/* Hierarchy */}
          <ProtectedRoute exact path="/d/admin/units" component={AdminUnits} />
          <ProtectedRoute
            exact
            path="/d/admin/hierarchy"
            component={Hierarchy}
          />
          <ProtectedRoute
            exact
            path="/d/admin/hierarchy/levels"
            component={HierarchyLevel}
          />

          {/* Admin View */}
          <ProtectedRoute exact path="/d/admin/users" component={Users} />
          <ProtectedRoute exact path="/d/admin/services" component={Services} />
          <ProtectedRoute
            exact
            path="/d/admin/masterlist"
            component={Master}
          />
          <ProtectedRoute
            exact
            path="/d/admin/pending/approvals"
            component={AdminApprovalRequests}
          />

          <ProtectedRoute
            exact
            path="/d/admin/add"
            component={AdminFacilityAddition}
          />
          <ProtectedRoute
            exact
            path="/d/admin/update"
            component={AdminFacilityUpdate}
          />
          <ProtectedRoute
            exact
            path="/d/admin/status"
            component={AdminFacilityStatus}
          />
          <ProtectedRoute
            exact
            path="/d/admin/deactivate"
            component={AdminFacilityDeactivation}
          />
          {/* <ProtectedRoute
            exact
            path="/d/admin/facility/requests"
            component={FacilityRequests}
          /> */}
          <ProtectedRoute
            exact
            path="/d/admin/facility/masterlist/:id"
            component={MasterListDetails}
          />
          <ProtectedRoute
            exact
            path="/d/admin/facility/requests/:id"
            component={FacilityRequestDetails}
          />
            {/* <ProtectedRoute
            exact
            path="/d/admin/my/requests"
            component={DirectRequests}
          /> */}
        </AdminLayout>
      </Switch>
    </Fragment>
  );
};

export default AdminRoutes;