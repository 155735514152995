import React from "react";
import MyRequests from "../../components/FNTables/MyRequests";

function DistrictFacilityRequestsMain() {
  return (
    <>
      <MyRequests/>
    </>
  );
}

export default DistrictFacilityRequestsMain;
