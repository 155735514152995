/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import API from "../../helpers/api";
import "./requestor.css";
import SideBar from "../../components/Layout/Admin/SideBar";

const DistrictRequests = () => {
  const [facilities, setFacilities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    districtRequests();
  }, []);

  const districtRequests = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await API.get("/requests/district", {
        headers: { Authorization: `Bearer ${token}` },
      });

      setFacilities(response.data || []);
    } catch (err) {
      setError(err.message || "Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  const handleFacilityClick = (id, name) => {
    history.push({
      pathname: `/d/district/details/${id}`,
      state: { facilityName: name },
    });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p style={{ color: "red" }}>{error}</p>;

  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-3">
          <SideBar />
        </div>
        <div className="col-9">
          <div class="card">
            <div class="card-header">
              <h6 class="slim-card-title">District Facility Requests</h6>
            </div>
            {facilities.length === 0 ? (
              <p className="text-center text-muted">
                There are No District Facility Requests Currently.
              </p>
            ) : (
              <>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Facility Name</th>
                      <th>Level of Care</th>
                      <th>Authority</th>
                      <th>Ownership</th>
                      <th>Status</th>
                      <th>Requested Date</th>
                      <th>Last Updated Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {facilities.map((unit) => (
                      <tr key={unit.id}>
                        <td>
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleFacilityClick(unit.id, unit.name);
                            }}
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                              color: "blue",
                            }}
                          >
                            {unit.name}
                          </a>
                        </td>
                        <td>{unit.level || "N/A"}</td>
                        <td>{unit.authority || "N/A"}</td>
                        <td>{unit.ownership || "N/A"}</td>
                        <td>{unit.status || "N/A"}</td>
                        <td>
                          {unit.createdAt
                            ? new Date(unit.createdAt).toLocaleDateString()
                            : "N/A"}
                        </td>
                        <td>
                          {unit.updatedAt
                            ? new Date(unit.updatedAt).toLocaleDateString()
                            : "N/A"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* <div class="card-footer tx-12 pd-y-15 bg-transparent">
                        <a href=""><i class="fa fa-angle-down mg-r-5"></i>Pagination</a>
                    </div> */}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DistrictRequests;
