"use client"

import { useState, useEffect } from "react"
import Select from "react-select"
import { toast } from "react-toastify"
import API from "../../../helpers/api"

const DeactiveFacility = () => {
  const [loading, setLoading] = useState(true)
  const [facilities, setFacilities] = useState([])
  const [reason, setReason] = useState("")
  const [facility_id, setFacilityId] = useState(null);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [supportDocument, setSupportDocument] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    level: "",
    ownership: "",
    authority: "",
    region_id: null,
    district_id: null,
    sub_county_id: null
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setSupportDocument(file);
  };

  useEffect(() => {
    fetchInitialData()
  }, [])

  const fetchInitialData = async () => {
    try {
      const token = localStorage.getItem("token")
      const [facilitiesRes] = await Promise.all([
        API.get("/facility/my", {
          headers: { Authorization: `Bearer ${token}` },
        }),
        API.get("/adminareas/regions"),
      ])

      const facilityData = facilitiesRes.data
      setFacilities(facilityData || [])
      setLoading(false)
    } catch (error) {
      console.error("Error fetching initial data:", error)
      toast.error("Failed to fetch initial data")
      setLoading(false)
    }
  }

  const handleFacilityChange = async (selectedOption) => {
    setLoading(true)
    try {
      const response = await API.get(`/facility/${selectedOption.value}`)
      const facilityData = response.data
      setFacilityId(selectedOption.value)
      setSelectedFacility(facilityData)

      setFormData({
        name: facilityData.name || "",
        level: facilityData.level || "",
        ownership: facilityData.ownership || "",
        authority: facilityData.authority || "",
        region_id: facilityData.Region?.id || null,
        district_id: facilityData.District?.id || null,
        sub_county_id: facilityData.SubCounty?.id || null,
      });

      console.log(selectedFacility)

    } catch (error) {
      console.error("Error fetching facility details:", error)
      toast.error("Failed to fetch facility details")
    }
    setLoading(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    // Validate required fields
    if (!reason) {
      toast.error("Please select a facility and provide a reason for deactivation")
      setLoading(false)
      return
    }

    try {
      const token = localStorage.getItem("token")
      const submissionData = new FormData();

      submissionData.append("reason", reason);
      submissionData.append("facility_id", facility_id);
      submissionData.append("name", formData.name || "");
      submissionData.append("level", formData.level || "");
      submissionData.append("ownership", formData.ownership || "");
      submissionData.append("authority", formData.authority || "");
      submissionData.append("region_id", formData.region_id || "");
      submissionData.append("district_id", formData.district_id || "");
      submissionData.append("sub_county_id", formData.sub_county_id || "");
      submissionData.append("request_type", "Deactivation_Request");
      submissionData.append("comments", "Deactivation Request Iniatied");
      submissionData.append("status", "Deactivation Request Iniatied");

      if (supportDocument) {
        submissionData.append("support_document", supportDocument);
      }

      await API.post("/requests", submissionData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success("Deactivation request submitted successfully")
      // Reset form
      setSelectedFacility(null)
    } catch (error) {
      console.error("Error submitting deactivation request:", error)
      toast.error(error.response?.data?.message || "Failed to submit deactivation request")
    }
    setLoading(false)
  }

  return (
    <div className="container">
      <div className="row ">
        <div className="col-12">
          <div className="col-10 mx-auto">
            <div className="facility-deactivation card shadow p-4">
              <h5 className="text-center">Facility Deactivation Request</h5>
              <div className="divider"></div>
              <div className="mb-4">
                <label className="form-label">Select Health Facility</label>
                <Select
                  placeholder={selectedFacility?.name}
                  options={facilities.map((f) => ({ value: f.id, label: f.name }))}
                  onChange={handleFacilityChange}
                  isDisabled={loading}
                />
              </div>

              {/* Deactivation Reason */}
              <h5 className="mt-4">Deactivation Remarks / Reason</h5>
              <textarea
                className="form-control"
                rows="4"
                name="reason"
                placeholder="Enter the reason for deactivation..."
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />

              {/* Supporting Document Upload */}
              <div className="mt-4">
                <label className="form-label">Upload Deactativation Supporting Document</label>
                <input
                  type="file"
                  className="form-control"
                  name="support_document"
                  onChange={handleFileChange}
                  disabled={!selectedFacility}
                />
              </div>

              {/* Submit Button */}
              <div className="text-center mt-4">
                <button className="btn btn-danger" onClick={handleSubmit} disabled={loading}>
                  {loading ? (
                    <span>Submitting...</span>
                  ) : (
                    <>
                      <i className="bx bx-x-circle"></i> Submit Deactivation Request
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeactiveFacility