import React from "react";

const Contacts = ({ formData, handleChange }) => {
  return (
    <div className="row">
      <div className="col-10 mx-auto">
        <h5 className="mb-4">Your Contact Details</h5>
        <div className="row">
          {/* <div className="col-6">
            <input
              type="text"
              value={formData.physicalLocation}
              onChange={handleChange}
              className="form-control mb-3"
              placeholder="Physical Address"
              name="physicalLocation"
            />
          </div> */}

          {/* <div className="col-6">
            <div className="row">
              <div className="col-6">
                {" "}
                <input
                  type="text"
                  value={formData.latitude}
                  onChange={handleChange}
                  className="form-control mb-3"
                  placeholder="Latitude"
                  name="latitude"
                />
              </div>
              <div className="col-6">
                {" "}
                <input
                  type="text"
                  value={formData.longitude}
                  onChange={handleChange}
                  className="form-control mb-3"
                  placeholder="Longitude"
                  name="longitude"
                />
              </div>
            </div>
          </div> */}

          <div className="col-6">
            <input
              type="text"
              name="contact_personname"
              value={formData.contact_personname}
              onChange={handleChange}
              className="form-control mb-3"
              placeholder="Contact Person Name"
            />
            <input
              type="text"
              name="contact_persontitle"
              value={formData.contact_persontitle}
              onChange={handleChange}
              className="form-control mb-3"
              placeholder="Contact Person Title"
            />
          </div>
          <div className="col-6">
            <input
              type="text"
              name="contact_personmobile"
              value={formData.contact_personmobile}
              onChange={handleChange}
              className="form-control mb-3"
              placeholder="Contact Person Mobile"
            />
            <input
              type="text"
              name="contact_personemail"
              value={formData.contact_personemail}
              onChange={handleChange}
              className="form-control mb-3"
              placeholder="Contact Person Email"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
