import React from 'react';
import { useHistory } from 'react-router-dom';

const Success = () => {
    const history = useHistory();

    return (
        <section>
            <div className="d-flex justify-content-center">
                <div className="w-50 card text-center p-4 shadow-lg">
                    <div className="card-body">
                        <i className="bx bx-check-circle text-success display-1"></i>
                        <h2 className="text-success mt-3">Registration Successful!</h2>
                        <p className="text-muted">
                            <i className="bx bx-envelope"></i> A confirmation email has been sent to your inbox.
                        </p>
                        <button className="btn btn-primary mt-3" onClick={() => history.push('/login')}>
                            Go to Home
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Success;
