/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import RequestorNav from './RequestorNav'
import DistrictNav from './DistrictNav'

const SideBar = () => {
    const user = JSON.parse(localStorage.getItem("user"))

    return (
        <div>
                {user && user.role === 'district' && <DistrictNav />}
                {user && user.role === 'guest' && <RequestorNav />}
        </div>
    )
}

export default SideBar