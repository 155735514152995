import React, { useState } from "react";

const TabbedLayout = ({ tabs }) => {
  const [data, setData] = useState(null);
  const [currentTab, setCurrentTab] = useState(tabs[0]?.key || "");

  return (
    <div className="container mb-3">
      <div className="row gap-5 mt-5">
        <div className="col-2">
          <div className="sidebar border rounded p-3 shadow-sm">
            <nav className="nav flex-column">
              {tabs.map((tab) => (
                <button
                  key={tab.key}
                  className={`nav-link btn btn-light text-start w-100 p-2 rounded mb-2 ${currentTab === tab.key ? "active" : ""
                    }`}
                  onClick={() => setCurrentTab(tab.key)}
                >
                  <i className={`bx ${tab.icon} me-2`}></i>
                  {tab.label}
                </button>
              ))}
            </nav>
          </div>
        </div>

        {/* Content */}
        <div className="col-9">
          {tabs.map(
            (tab) =>
              currentTab === tab.key && (
                <div key={tab.key}>{React.cloneElement(tab.component, { data })}</div>
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default TabbedLayout;
