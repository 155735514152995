import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom'
import API from '../../helpers/api';
import { toast } from 'react-toastify';
import pic from './uganda logo.jpeg'

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const history = useHistory();

    const handleLogin = async (e) => {
        e.preventDefault();
        if (!email || !password) {
            toast.error("Please enter both email and password.", { position: "top-center" });
            return;
        }

        try {
            const res = await API.post('/users/login', { email, password });

            localStorage.setItem("token", res.data.token);
            localStorage.setItem("user", JSON.stringify(res.data.user));

            toast.success("Login successful!", { position: "top-center" });

            if (res.data.user.role === 'admin') {
                history.push('/d/admin/pending/approvals')
            } else if (res.data.user.role === 'public') {
                history.push('/d/requestor')
            } else if (res.data.user.role === 'district') {
                history.push('/d/district')
            } else {
                history.push('/public')
            }

        } catch (error) {
            toast.error(error.response?.data.message || 'Login failed', { position: "top-center" });
        }
    };

    return (

        <section>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-5">
                        <div class="card border border-light-subtle rounded-3 shadow-sm">
                            <div class="card-body p-4">
                                <div class="text-center mb-3">
                                    <a href="#!">
                                        <img src={pic} alt="MOH" class="img-fluid" style={{ maxWidth: '100px' }} />
                                    </a>
                                </div>
                                <h2 class="fs-5 fw-bold text-center mb-3">
                                    Ministry of Health <br /> National Health Facility Registry
                                </h2>
                                <form onSubmit={handleLogin}>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="mb-3 input-group">
                                                <span class="input-group-text"><i class="bx bx-envelope"></i></span>
                                                <input class="form-control"
                                                    type="text"
                                                    className="form-control"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    placeholder='E-mail or username'
                                                />
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="mb-3 input-group">
                                                <span class="input-group-text"><i class="bx bx-lock"></i></span>
                                                <input class="form-control"
                                                    type="password"
                                                    className="form-control"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    placeholder='Password' />
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="d-flex gap-2 justify-content-between">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" name="rememberMe" id="rememberMe" />
                                                    <label class="form-check-label text-secondary" for="rememberMe">
                                                        Keep me logged in
                                                    </label>
                                                </div>
                                                <Link to="/p/forgotpassword" class="link-primary text-decoration-none">Forgot password?</Link>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="d-grid my-3">
                                                <button class="btn btn-primary btn-lg" type="submit">
                                                    <i class="bx bx-log-in"></i> Log in
                                                </button>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <p class="m-0 text-secondary text-center">
                                                Don't have an account?
                                                <Link to="/p/registration" class="link-primary text-decoration-none">Sign up</Link>
                                            </p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Login;