import React from 'react'

const Classification = ({ formData, handleChange }) => {
    return (
        <div className="row">
            <div className="col-10 mx-auto">
                <h5 className="mb-4">Health Facility Classification</h5>
                <div className="row">
                    <div className="col-6">
                        <div class="mb-3">
                            <select class="form-select custom-select" id="facilityType" aria-label="Select Level of Care"
                                value={formData.leve}
                                onChange={handleChange}
                                name="level"
                            >
                                <option value="" disabled selected>-- Select Level of Care --</option>
                                {/* <option value="Specialized Hospital">Specialized Hospital</option>
                                <option value="National Referral Hospital">National Referral Hospital</option>
                                <option value="Regional Referral Hospital">Regional Referral Hospital</option>
                                <option value="Referral Hospital">Referral Hospital</option>
                               
                                <option value="Health Centre IV">Health Centre IV [HC IV]</option>
                                <option value="Health Centre III">Health Centre III [HC III]</option>
                                <option value="Health Centre II">Health Centre II [HC II]</option> */}

                                <option value="HC II">HC II</option>
                                <option value="Clinic">Clinic</option>
                                <option value="Pharmacy">Pharmacy</option>
                                <option value="Drug Shop">Drug Shop</option>
                                <option value="General Hospital">General Hospital</option>
                                <option value="Radiology (Imaging)">Radiology (Imaging)</option>
                                <option value="Laboratory/Diagnostic center">Laboratory/Diagnostic center</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <select class="form-select custom-select" id="ownershipType" aria-label="Select Ownership Type"
                                value={formData.ownership}
                                onChange={handleChange}
                                name="ownership"
                            >
                                <option value="" disabled selected>-- Select Ownership --</option>
                                {/* <option value="Public">Public</option> */}
                                <option value="PFP">Private For Profit </option>
                                <option value="PNFP">Private Not For Profit </option>
                            </select>
                        </div>

                    </div>
                    <div className="col-6">
                        <div class="mb-3">
                            <select class="form-select custom-select" id="organizationType" aria-label="Select Health Facility Authority"
                                value={formData.authority}
                                onChange={handleChange}
                                name="authority"
                            >
                                <option value="" disabled selected>-- Select Facility Authority --</option>
                                {/* <option value="Ministry of Health">Ministry of Health</option>
                                <option value="Uganda People’s Defense Force">Uganda People’s Defense Force</option>
                                <option value="Uganda Prison Services">Uganda Prison Services</option>
                                <option value="Uganda Police Force">Uganda Police Force</option> */}
                                <option value="NGO">NGO</option>
                                <option value="UCBHCA">UCBHCA</option>
                                <option value="UCMB">UCMB</option>
                                <option value="UPMB">UPMB</option>
                                <option value="UOMB">UOMB</option>
                                <option value="UMMB">UMMB</option>
                            </select>
                        </div>
                        {/* <div class="mb-3">
                            <select class="form-select custom-select" id="facilityStatus" aria-label="Select Operational Status"
                                value={formData.operationalStatus}
                                onChange={handleChange}
                                 name="operationalStatus"
                            >
                                <option value="" disabled selected>-- Select Operational Status --</option>
                                <option value="Operational/Functional">Operational/Functional</option>
                                <option value="Non Functional">Non Functional</option>
                            </select>
                        </div> */}
                        <div class="mb-3">
                            <select class="form-select custom-select" id="licenseStatus" aria-label="Select License Status"
                                value={formData.license_status}
                                onChange={handleChange}
                                name="license_status"
                            >
                                <option value="" disabled selected>-- Select License Status --</option>
                                <option value="Licenced">Licenced</option>
                                <option value="License Expired">License Expired</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Classification