import React from "react";
import { Link } from "react-router-dom";
import "./sidebar.css";

const DistrictNav = () => {
    return (
        <div className="sidebar">
            {/* Sidebar Header Button */}
            <button className="btn btn-primary sidebar-header">
                <i className="bx bx-plus-circle"></i> Request Actions
            </button>

            {/* Navigation Links */}
            <nav className="nav flex-column">
                <Link to="/d/district/myrequests" className="nav-link">
                    <i className="bx bx-list-ul"></i> <span>My Requests</span>
                </Link>
                <Link to="/d/district/addition" className="nav-link">
                    <i className="bx bx-buildings"></i> <span>Facility Addition</span>
                </Link>
                <Link to="/d/district/update" className="nav-link">
                    <i className="bx bx-edit"></i> <span>Facility Update</span>
                </Link>
                <Link to="/d/district/status" className="nav-link">
                    <i className="bx bx-user"></i> <span>Request Status</span>
                </Link>
                <Link to="/d/district/deactivate" className="nav-link">
                    <i className="bx bx-x-circle"></i> <span>Facility Deactivate</span>
                </Link>
            </nav>
        </div>
    );
};

export default DistrictNav;
