import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import API from '../../../helpers/api';
import { toast } from 'react-toastify';

const Registration = () => {
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        phone_no: '',
    });

    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);

    const history = useHistory();

    const validateField = (name, value) => {
        let error = '';

        if (!value.trim()) {
            error = `${name.replace('_', ' ')} is required`;
        } else if (name === 'email' && !/^\S+@\S+\.\S+$/.test(value)) {
            error = 'Invalid email format';
        } else if (name === 'password' && value.length < 6) {
            error = 'Password must be at least 6 characters';
        } else if (name === 'phone_no' && !/^\d+$/.test(value)) {
            error = 'Phone number must contain only numeric digits';
        }

        return error;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (submitted) {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: validateField(name, value) }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true);

        let newErrors = {};
        Object.keys(formData).forEach((key) => {
            newErrors[key] = validateField(key, formData[key]);
        });

        setErrors(newErrors);

        if (Object.values(newErrors).some((error) => error !== '')) {
            toast.error("Please fix errors before submitting.", { position: "top-center" });
            return;
        }

        try {
            const response = await API.post('/users/register/public', formData);
            toast.success(response.data.message, { position: "top-center" });
            history.push('/p/success');
        } catch (error) {
            toast.error(error.response?.data.message || 'Error registering user', { position: "top-center" });
            history.push('/p/failed', { error: error.response?.data.message });
        }
    };

    return (
        <section>
            <div className="d-flex justify-content-center align-items-center">
                <div className="w-50 p-4 border bg-light">
                    <h2 className="text-center mb-4">User Account Registration</h2>
                    <form onSubmit={handleSubmit}>
                        {/* First Name */}
                        <div className="mb-3 row">
                            <div className="col-md-10 mx-auto">
                                <div className="input-group">
                                    <span className="input-group-text"><i className="bx bx-user"></i></span>
                                    <input
                                        placeholder="First Name"
                                        type="text"
                                        name="firstname"
                                        className={`form-control ${errors.firstname ? 'is-invalid' : ''}`}
                                        value={formData.firstname}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="invalid-feedback d-block">{errors.firstname}</div>
                            </div>
                        </div>

                        {/* Last Name */}
                        <div className="mb-3 row">
                            <div className="col-md-10 mx-auto">
                                <div className="input-group">
                                    <span className="input-group-text"><i className="bx bx-user"></i></span>
                                    <input
                                        placeholder="Last Name"
                                        type="text"
                                        name="lastname"
                                        className={`form-control ${errors.lastname ? 'is-invalid' : ''}`}
                                        value={formData.lastname}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="invalid-feedback d-block">{errors.lastname}</div>
                            </div>
                        </div>

                        {/* Email */}
                        <div className="mb-3 row">
                            <div className="col-md-10 mx-auto">
                                <div className="input-group">
                                    <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                                    <input
                                        placeholder="E-Mail Address"
                                        type="email"
                                        name="email"
                                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="invalid-feedback d-block">{errors.email}</div>
                            </div>
                        </div>

                        {/* Password */}
                        <div className="mb-3 row">
                            <div className="col-md-10 mx-auto">
                                <div className="input-group">
                                    <span className="input-group-text"><i className="bx bx-lock"></i></span>
                                    <input
                                        placeholder="Password"
                                        type="password"
                                        name="password"
                                        className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                        value={formData.password}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="invalid-feedback d-block">{errors.password}</div>
                            </div>
                        </div>

                        {/* Phone */}
                        <div className="mb-3 row">
                            <div className="col-md-10 mx-auto">
                                <div className="input-group">
                                    <span className="input-group-text"><i className="bx bx-phone"></i></span>
                                    <input
                                        placeholder="Phone Number"
                                        type="tel"
                                        name="phone_no"
                                        pattern="\d*"
                                        className={`form-control ${errors.phone_no ? 'is-invalid' : ''}`}
                                        value={formData.phone_no}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="invalid-feedback d-block">{errors.phone_no}</div>
                            </div>
                        </div>

                        {/* Health Facility */}
                        {/* <div className="mb-3 row">
                            <div className="col-md-10 mx-auto">
                                <div className="input-group">
                                    <span className="input-group-text"><i className="bx bx-map"></i></span>
                                    <input
                                        placeholder="Health Facility"
                                        type="text"
                                        name="health_facility"
                                        className={`form-control ${errors.health_facility ? 'is-invalid' : ''}`}
                                        value={formData.health_facility}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="invalid-feedback d-block">{errors.health_facility}</div>
                            </div>
                        </div> */}

                        {/* District */}
                        {/* <div className="mb-3 row">
                            <div className="col-md-10 mx-auto">
                                <div className="input-group">
                                    <span className="input-group-text"><i className="bx bx-home"></i></span>
                                    <input
                                        placeholder="District"
                                        type="text"
                                        name="district"
                                        className={`form-control ${errors.district ? 'is-invalid' : ''}`}
                                        value={formData.district}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="invalid-feedback d-block">{errors.district}</div>
                            </div>
                        </div> */}

                        {/* Submit Button */}
                        <div className="mb-3 row">
                            <div className="col-md-10 mx-auto d-grid">
                                <button type="submit" className="btn btn-primary">
                                    <i className="bx bx-send"></i> Register Account
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default Registration;
