import React from 'react'
import RequestHistory from '../../../../components/FNTables/StatusTracking'
import Layout from '../layout'

function AdminFacilityStatus() {
  return (
    <Layout><RequestHistory/></Layout>
  )
}

export default AdminFacilityStatus