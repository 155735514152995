import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PublicLayout from '../components/Layout/Public';


import Dashboard from '../pages/Public/Dashboard';
import Facilities from '../pages/Public/Facilities';
import Guidelines from '../pages/Public/Guidelines';
import Registration from '../pages/Public/Registration';
import Failed from '../pages/Public/Registration/Failed';
import Success from '../pages/Public/Registration/Success';
import FacilityDetails from '../pages/Public/Facilities/FacilityDetails';
import ForgotPassword from '../pages/Public/Registration/ForgotPassword';

const PublicRoutes = () => {
    return (
        <PublicLayout>
            <Switch>
                <Route exact path="/p" component={Dashboard} />
                <Route exact path="/p/failed" component={Failed} />
                <Route exact path="/p/success" component={Success} />
                <Route exact path="/p/guidelines" component={Guidelines} />
                <Route exact path="/p/facility/list" component={Facilities} />
                <Route exact path="/p/facility/:id" component={FacilityDetails} />
                <Route exact path="/p/registration" component={Registration} />
                <Route exact path="/p/forgotpassword" component={ForgotPassword} />
            </Switch>
        </PublicLayout>
    );
};

export default PublicRoutes;