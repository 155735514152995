import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import API from '../../../../helpers/api';
import SideBar from '../SideBar';
import CustomSideBar from '../../../../components/reusable/CustomSideBar';

const HierarchyLevels = () => {
    const [hierarchyLevels, setHierarchyLevels] = useState([]);
    const [hierarchies, setHierarchies] = useState([]);
    const [newLevel, setNewLevel] = useState('');
    const [hierarchyId, setHierarchyId] = useState('');
    const [editingId, setEditingId] = useState(null);

    const location = useLocation();

    useEffect(() => {
        fetchLevels();
        fetchHierarchies();
    }, []);

    const fetchLevels = async () => {
        try {
            const response = await API.get('/hierarchylevel');
            setHierarchyLevels(response.data);
        } catch (error) {
            console.error('Error fetching hierarchy levels:', error);
        }
    };

    const fetchHierarchies = async () => {
        try {
            const response = await API.get('hierarchy');
            setHierarchies(response.data);
        } catch (error) {
            console.error('Error fetching hierarchies:', error);
        }
    };

    const handleAddLevel = async () => {
        if (!newLevel || !hierarchyId) {
            alert('Please provide a level and hierarchy.');
            return;
        }

        try {
            await API.post('/hierarchylevel', {
                level: parseInt(newLevel),
                hierarchy_id: parseInt(hierarchyId),
            });

            setNewLevel('');
            setHierarchyId('');
            fetchLevels();
        } catch (error) {
            alert('Failed to add hierarchy level');
        }
    };

    const handleUpdateLevel = async () => {
        if (!editingId || !newLevel || !hierarchyId) {
            alert('Please provide valid details.');
            return;
        }

        try {
            await API.put(`/hierarchylevel/${editingId}`, {
                level: parseInt(newLevel),
                hierarchy_id: parseInt(hierarchyId),
            });

            setEditingId(null);
            setNewLevel('');
            setHierarchyId('');
            fetchLevels();
        } catch (error) {
            alert('Failed to update hierarchy level');
        }
    };

    const handleDeleteLevel = async (id) => {
        if (window.confirm('Are you sure you want to delete this level?')) {
            try {
                await API.delete(`/hierarchylevel/${id}`);
                fetchLevels();
            } catch (error) {
                alert('Failed to delete hierarchy level');
            }
        }
    };


    const tabs = [
        // { path: "#", icon: "bx-list-ul", label: "Hierarchy Actions" },
        { path: "/d/admin/hierarchy", icon: "bx-list-ul", label: "Hierarchy" },
        {
          path: "/d/admin/hierarchy/levels",
          icon: "bx-buildings",
          label: "Hierarchy Levels",
        },
        { path: "/d/admin/units", icon: "bx-edit", label: "Administrative Areas" },
      ];

    return (
        <div className="container mt-5">
            <div className="row">
                {/* Sidebar */}
                <div className="col-lg-3">
                    <CustomSideBar tabs={tabs} heading="Hierachy Actions"/>
                </div>

                {/* Main Content */}
                <div className="col-lg-9">
                    {/* Title Section */}
                    <div className="title-section shadow-sm p-3 rounded">
                        <h5 className="mb-0"><i className="bx bx-sitemap"></i> Hierarchy Levels</h5>
                    </div>

                    {/* Form Section */}
                    <div className="card p-3 mb-4 shadow-sm">
                        <div className="row g-2">
                            <div className="col-md-4">
                                <select className="form-select custom-select" value={hierarchyId} onChange={(e) => setHierarchyId(e.target.value)}>
                                    <option value="">Select Hierarchy</option>
                                    {hierarchies.map((hierarchy) => (
                                        <option key={hierarchy.id} value={hierarchy.id}>
                                            {hierarchy.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-4">
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Level"
                                    value={newLevel}
                                    onChange={(e) => setNewLevel(e.target.value)}
                                />
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                                {editingId ? (
                                    <button className="btn btn-success " onClick={handleUpdateLevel}>
                                        <i className="bx bx-save"></i> Update
                                    </button>
                                ) : (
                                    <button className="btn btn-primary " onClick={handleAddLevel}>
                                        <i className="bx bx-plus"></i> Add
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Table Section */}
                    <div className="table-responsive">
                        <table className="table table-striped table-bordered">
                            <thead className="table-light">
                                <tr>
                                    <th>Hierarchy</th>
                                    <th>Hierarchy Level</th>
                                    <th className="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {hierarchyLevels.map((level) => (
                                    <tr key={level.id}>
                                        <td>{level.hierarchy.name}</td>
                                        <td>{level.level}</td>
                                        <td className="text-center">
                                            <div className="d-flex justify-content-center align-items-center gap-2">
                                                <button
                                                    className="btn btn-warning btn-sm"
                                                    onClick={() => {
                                                        setEditingId(level.id);
                                                        setNewLevel(level.level);
                                                        setHierarchyId(level.hierarchy.id);
                                                    }}
                                                >
                                                    <i className="bx bx-edit"></i> Edit
                                                </button>
                                                <button
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() => handleDeleteLevel(level.id)}
                                                >
                                                    <i className="bx bx-trash"></i> Delete
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HierarchyLevels;
