import React from "react";
import MyRequests from "../../../components/FNTables/MyRequests";
import Layout from "./layout";

function AdminFacilityRequestsMain() {

  return (
    <>
      <Layout ><MyRequests /></Layout>
    </>
  );
}

export default AdminFacilityRequestsMain;
