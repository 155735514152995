import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children }) => {
  return (
    <>
      <>
        <Header />
        <div className="container py-5 ">
          <div className="row gap-5 py-5">
            <div className="col-12 ">{children}</div>
            <div className="col-12">
              <Footer />
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Layout;
