"use client"

import { useState, useEffect } from "react"
import { Bar, Doughnut, Line } from "react-chartjs-2"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
)

// Mock API for demonstration purposes
const API = {
  get: async (endpoint) => {
    // Simulated API responses
    const data = {
      "/facility/stats": [
        { ownership: "GOV", count: 2500 },
        { ownership: "PNFP", count: 1500 },
        { ownership: "PNP", count: 1000 },
        { ownership: "Other", count: 500 },
      ],
      "/facility/level": [
        { level: "National Referral Hospital", count: 50 },
        { level: "Regional Referral Hospital", count: 100 },
        { level: "General Hospital", count: 200 },
        { level: "Health Center IV", count: 500 },
        { level: "Health Center III", count: 1500 },
        { level: "Health Center II", count: 3150 },
      ],
      "/facility/ownership": [
        { ownership: "GOV", authority: "Ministry of Health", count: 2000 },
        { ownership: "GOV", authority: "Local Government", count: 500 },
        { ownership: "PNFP", authority: "Faith-based", count: 1000 },
        { ownership: "PNFP", authority: "NGO", count: 500 },
        { ownership: "PNP", authority: "Private", count: 1000 },
        { ownership: "Other", authority: "Other", count: 500 },
      ],
    }
    return { data: { data: data[endpoint] } }
  },
}

const CustomDash = () => {
  const [facilityCounts, setFacilityCounts] = useState([])
  const [levelStats, setLevelStats] = useState([])
  const [ownershipData, setOwnershipData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [activeTab, setActiveTab] = useState("overview")
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [facilitiesResponse, levelResponse, ownershipResponse] = await Promise.all([
          API.get("/facility/stats"),
          API.get("/facility/level"),
          API.get("/facility/ownership"),
        ])

        setFacilityCounts(facilitiesResponse.data.data)
        setLevelStats(levelResponse.data.data)
        setOwnershipData(ownershipResponse.data.data)
        setLoading(false)
      } catch (err) {
        console.error("Error fetching data:", err)
        setError("Failed to load dashboard data. Please try again later.")
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  if (loading) return <LoadingSpinner />
  if (error)
    return (
      <div className="alert alert-danger m-3" role="alert">
        {error}
      </div>
    )

  const totalFacilities = facilityCounts.reduce((acc, facility) => acc + Number.parseInt(facility.count, 10), 0)

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = ownershipData.slice(indexOfFirstItem, indexOfLastItem)
  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  return (
    <div className="dashboard container relative">
     

      <div className="dashboard-content">
      <div className="container-fluid  p-4">
          <a className="navbar-brand" href="#">
            UNHFR Dashboard
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div >
            <ul className="flex items-center ">
              <li className="">
                <a
                  className={`nav-link ${activeTab === "overview" ? "active" : ""}`}
                  href="#"
                  onClick={() => setActiveTab("overview")}
                >
                  Overview
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${activeTab === "details" ? "active" : ""}`}
                  href="#"
                  onClick={() => setActiveTab("details")}
                >
                  Detailed View
                </a>
              </li>
            </ul>
          </div>
        </div>
        <header className="dashboard-header">
          <h1 className="dashboard-title">Uganda National Health Facility Registry</h1>
          <p className="dashboard-subtitle">Overview of {totalFacilities.toLocaleString()} health facilities</p>
        </header>

        {activeTab === "overview" && (
          <>
            <div className="row g-4 mb-5">
              {facilityCounts.map((facility, index) => (
                <div key={index} className="col-md-6 col-lg-3">
                  <StatCard title={facility.ownership} value={facility.count} icon={facility.ownership} />
                </div>
              ))}
            </div>

            <div className="row g-4 mb-5">
              <div className="col-lg-8">
                <div className="chart-card">
                  <h5 className="chart-card-title">Facility Distribution by Level of Care</h5>
                  <BarChart data={levelStats} />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="chart-card">
                  <h5 className="chart-card-title">Ownership Distribution</h5>
                  <DoughnutChart data={facilityCounts} />
                </div>
              </div>
            </div>

            <div className="row g-4 mb-5">
              <div className="col-12">
                <div className="chart-card">
                  <h5 className="chart-card-title">Facility Growth Over Time</h5>
                  <LineChart />
                </div>
              </div>
            </div>
          </>
        )}

        {activeTab === "details" && (
          <div className="row g-4 mb-5">
            <div className="col-12">
              <div className="data-card">
                <div className="data-card-header">
                  <h5 className="data-card-title">Facility Distribution by Ownership & Authority</h5>
                  <div className="data-card-actions">
                    <button className="btn btn-sm btn-outline-primary">Filter</button>
                    <button className="btn btn-sm btn-outline-secondary">Export CSV</button>
                  </div>
                </div>
                <div className="data-card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>Ownership</th>
                          <th>Authority</th>
                          <th>Count</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map((item, index) => (
                          <tr key={index}>
                            <td>{item.ownership}</td>
                            <td>{item.authority}</td>
                            <td>{item.count}</td>
                            <td>
                              <button className="btn btn-sm btn-link">View</button>
                              <button className="btn btn-sm btn-link">Edit</button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <nav>
                    <ul className="pagination justify-content-center">
                      {Array.from({ length: Math.ceil(ownershipData.length / itemsPerPage) }, (_, i) => (
                        <li key={i} className={`page-item ${currentPage === i + 1 ? "active" : ""}`}>
                          <button onClick={() => paginate(i + 1)} className="page-link">
                            {i + 1}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const LoadingSpinner = () => (
  <div className="loading-spinner">
    <div className="spinner-border text-primary" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
)

const StatCard = ({ title, value, icon }) => {
  const facilityIcons = {
    GOV: "bi-bank",
    PNFP: "bi-building",
    PNP: "bi-house",
    Other: "bi-hospital",
  }

  return (
    <div className="stat-card">
      <div className="stat-card-content">
        <div className="stat-card-icon">
          <i className={`bi ${facilityIcons[icon] || facilityIcons["Other"]}`}></i>
        </div>
        <div className="stat-card-info">
          <h6 className="stat-card-title">{title}</h6>
          <p className="stat-card-value">{Number.parseInt(value, 10).toLocaleString()}</p>
        </div>
      </div>
    </div>
  )
}

const BarChart = ({ data }) => {
  const chartData = {
    labels: data.map((stat) => stat.level),
    datasets: [
      {
        label: "Facility Count",
        data: data.map((stat) => stat.count),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Facility Distribution by Level of Care",
      },
    },
  }

  return <Bar data={chartData} options={options} />
}

const DoughnutChart = ({ data }) => {
  const chartData = {
    labels: data.map((facility) => facility.ownership),
    datasets: [
      {
        data: data.map((facility) => facility.count),
        backgroundColor: [
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Ownership Distribution",
      },
    },
  }

  return <Doughnut data={chartData} options={options} />
}

const LineChart = () => {
  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "Facility Growth",
        data: [12, 19, 3, 5, 2, 3],
        fill: true,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
      },
    ],
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Facility Growth Over Time",
      },
    },
  }

  return <Line data={data} options={options} />
}

export default CustomDash