import React, { useState, useEffect } from 'react';
import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import API from '../../../../helpers/api';
import SideBar from "../SideBar";
import { FaPlus, FaEllipsisV, FaEdit, FaTrash, FaSearch } from 'react-icons/fa';
import CustomSideBar from '../../../../components/reusable/CustomSideBar';

const ItemTypes = { NODE: 'NODE' };

const AdminUnits = () => {
    const [treeData, setTreeData] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [modal, setModal] = useState(false);
    const [modalType, setModalType] = useState(""); // "add" or "edit"
    const [formData, setFormData] = useState({ name: '', parentId: null, parentType: '' });

    useEffect(() => { fetchHierarchy(); }, []);

    const fetchHierarchy = async () => {
        try {
            const response = await API.get('/adminareas');
            setTreeData(formatData(response.data));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const formatData = (data) => {
        return data.map(region => ({
            title: region.name,
            key: `region-${region.id}`,
            children: region.localGovernments.map(lg => ({
                title: lg.name,
                key: `lg-${lg.id}`,
                children: lg.subCounties.map(sub => ({
                    title: sub.name,
                    key: `sub-${sub.id}`,
                    children: sub.facilities.map(fac => ({
                        title: fac.name,
                        key: `fac-${fac.id}`
                    }))
                }))
            }))
        }));
    };

    const toggleDropdown = (key) => {
        setDropdownOpen(dropdownOpen === key ? null : key);
    };

    const openModal = (type, parentKey = null, parentType = "") => {
        setModalType(type);
        setFormData({ name: '', parentId: parentKey ? parentKey.split('-')[1] : null, parentType });
        setModal(true);
    };

    const closeModal = () => {
        setModal(false);
        setFormData({ name: '', parentId: null, parentType: '' });
    };

    const handleSubmit = async () => {
        if (!formData.name) return alert("Name is required!");

        try {
            if (modalType === "add") {
                await API.post('/adminareas', formData);
            } else {
                await API.put(`/adminareas/${formData.parentId}`, { name: formData.name, type: formData.parentType });
            }
            fetchHierarchy();
            closeModal();
        } catch (error) {
            console.error("Error saving data:", error);
        }
    };

    const handleDelete = async (key, type) => {
        if (!window.confirm(`Are you sure you want to delete this ${type}?`)) return;
        const id = key.split('-')[1];

        try {
            await API.delete(`/adminareas/${id}?type=${type}`);
            fetchHierarchy();
        } catch (error) {
            console.error("Error deleting unit:", error);
        }
    };

    const renderTitle = (node, type) => (
        <div className="d-flex align-items-center justify-content-between w-100">
            <span>{node.title}</span>

            <div className="d-flex">
                <FaPlus className="text-success mx-2" onClick={() => openModal("add", node.key, type)} style={{ cursor: 'pointer' }} />

                <Dropdown isOpen={dropdownOpen === node.key} toggle={() => toggleDropdown(node.key)}>
                    <DropdownToggle tag="span">
                        <FaEllipsisV style={{ cursor: 'pointer' }} />
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={() => openModal("edit", node.key, type)}>
                            <FaEdit className="me-2 text-primary" /> Edit
                        </DropdownItem>
                        <DropdownItem onClick={() => handleDelete(node.key, type)}>
                            <FaTrash className="me-2 text-danger" /> Delete
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
        </div>
    );

    const tabs = [
        // { path: "#", icon: "bx-list-ul", label: "Hierarchy Actions" },
        { path: "/d/admin/hierarchy", icon: "bx-list-ul", label: "Hierarchy" },
        {
          path: "/d/admin/hierarchy/levels",
          icon: "bx-buildings",
          label: "Hierarchy Levels",
        },
        { path: "/d/admin/units", icon: "bx-edit", label: "Administrative Areas" },
      ];

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="container mt-5 mt-3">
                <div className="row">
                    <div className="col-md-3"><CustomSideBar tabs={tabs} heading="Hierachy Actions"/></div>
                    <div className="col-md-6">
                        <div className="card shadow-sm border-0">
                            <div className="card-header bg-light d-flex justify-content-between align-items-center">
                                <h5 className="mb-0"><i className="bx bx-sitemap"></i> Administrative Hierarchy</h5>
                                <button className="btn btn-success btn-sm" onClick={() => openModal("add", null, "None")}>
                                    <FaPlus /> Add Region
                                </button>
                            </div>
                            <div className="card-body">
                                <div className="mb-3">
                                    <FaSearch className="me-2" />
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </div>

                                <Tree
                                    treeData={treeData.filter(node => node.title.toLowerCase().includes(searchTerm.toLowerCase()))
                                        .map(region => ({
                                            ...region,
                                            title: renderTitle(region, "Region"),
                                            children: region.children.map(lg => ({
                                                ...lg,
                                                title: renderTitle(lg, "LocalGovernment"),
                                                children: lg.children.map(sub => ({
                                                    ...sub,
                                                    title: renderTitle(sub, "SubCounty"),
                                                    children: sub.children.map(fac => ({
                                                        ...fac,
                                                        title: renderTitle(fac, "Facility")
                                                    }))
                                                }))
                                            }))
                                        }))}
                                    defaultExpandAll
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal for Add/Edit */}
                <Modal isOpen={modal} toggle={closeModal}>
                    <ModalHeader toggle={closeModal}>{modalType === "add" ? "Add New Unit" : "Edit Unit"}</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Label>Name</Label>
                                <Input
                                    type="text"
                                    value={formData.name}
                                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                    required
                                />
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleSubmit}>{modalType === "add" ? "Add" : "Update"}</Button>
                        <Button color="secondary" onClick={closeModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        </DndProvider>
    );
};

export default AdminUnits;


